/*
Image Handler 4 v4.3.2
*/


#trailimageid {
  position: absolute;
  visibility: hidden;
  left: 0px;
  top: 0px;
  width: 220px;
  height: 0px;
  z-index: 1000;
}

#trailimageid div {
  padding: 5px;
  background-color: #FFF;
  border: 1px solid #EAEAEA;
  text-align: center;
  box-shadow: 0 5px 5px 2px #CCCCCC;
}

#trailimageid h1 {
  font-size: 14px;
  text-transform:uppercase;
  font-weight: normal;
  margin: 0 0 5px;
  padding: 5px 2px;
  text-align: left;
  width: 100%;
}

#trailimageid img {
  margin: 2px;
  border: none;
}
