/*@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700);
#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}*/
#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#cssmenu #menu-button {
  display: none;
}
/*#cssmenu {
  font-family: Montserrat, sans-serif;
  background: #333333;
}*/
#cssmenu > ul > li {
  float: left;
}
#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}
#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}
#cssmenu.align-center ul ul {
  text-align: left;
}
#cssmenu.align-right > ul > li {
  float: right;
}
/*#cssmenu > ul > li > a {
  padding: 17px;
  font-size: 12px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #dddddd;
  font-weight: 700;
  text-transform: uppercase;
}
#cssmenu > ul > li:hover > a {
  color: #ffffff;
}
*/
/*#cssmenu > ul > li.has-sub:after {
  position: absolute;
  top: 22px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #dddddd;
  content: '';
}*/
#cssmenu > ul > li.has-sub:before {
    border-bottom: 4px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: 2px;
    top: 18px;
    transition: all 0.25s ease 0s;
    width: 0;
    z-index: 100;
	border-top: 4px solid #FFFFFF;
}
#cssmenu > ul > li.has-sub > a {
  padding: 10px 25px 10px 15px;
}
#cssmenu .nav.open li.has-sub:before {
	content: none;
}
#cssmenu ul ul li.has-sub:before {
    border-bottom: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: 10px;
    top: 16px;
    transition: all 0.25s ease 0s;
    width: 0;
    z-index: 999;
}
/*#cssmenu > ul > li.has-sub:hover:before {
  top: 23px;
  height: 0;
}*/
#cssmenu ul ul {
  position: absolute;
  left: -9999px;
}
#cssmenu.align-right ul ul {
  text-align: right;
}
#cssmenu ul ul li {
  height: 0;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}
#cssmenu li:hover > ul {
  left: auto;
}
#cssmenu.align-right li:hover > ul {
  left: auto;
  right: 0;
}
#cssmenu li:hover > ul > li {
  height: auto;
}
#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}
#cssmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}
#cssmenu.align-right ul ul li.has-sub:after {
  right: auto;
  left: 11px;
}
/*#cssmenu ul ul li.has-sub:hover:before {
	border-left: 4px solid #FFFFFF;
}*/
#cssmenu.align-right ul ul li.has-sub:before {
  right: auto;
  left: 14px;
}
#cssmenu.small-screen {
  width: 100%;
  float:left;
}
#cssmenu.small-screen > ul {
  display: none;
  background: none repeat scroll 0 0 #F1F1F1;
  width:100%;
  z-index: 999;
}
#cssmenu.small-screen.align-center > ul {
  text-align: left;
}
#cssmenu.small-screen ul li, #cssmenu.small-screen ul ul li {
  width: 100%;
  border-top: 1px solid rgba(120, 120, 120, 0.2);
}
#cssmenu.small-screen ul li a, #cssmenu.small-screen ul ul li a {
	border-bottom: medium none;
	width: 100%;
}
#cssmenu.small-screen ul ul li,
#cssmenu.small-screen li:hover > ul > li {
  height: auto;
}
#cssmenu.small-screen ul li a,
#cssmenu.small-screen ul ul li a {
  width: 100%;
  border-bottom: 0;
}
#cssmenu.small-screen > ul > li {
  float: none;
}
#cssmenu.small-screen ul ul li a {
  padding-left: 25px;
  line-height: 11px;
}
#cssmenu.small-screen ul ul ul li a {
  padding-left: 35px;
}
/*#cssmenu.small-screen ul ul li a {
  color: #dddddd;
  background: none;
}
#cssmenu.small-screen ul ul li:hover > a,
#cssmenu.small-screen ul ul li.active > a {
  color: #ffffff;
}*/
#cssmenu.small-screen ul ul,
#cssmenu.small-screen ul ul ul,
#cssmenu.small-screen.align-right ul ul {
  position: relative;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: left;
  border: none;
  padding:0;
}
#cssmenu.small-screen > ul > li.has-sub > a:after,
#cssmenu.small-screen > ul > li.has-sub > a:before,
#cssmenu.small-screen ul ul > li.has-sub > a:after,
#cssmenu.small-screen ul ul > li.has-sub > a:before {
  display: none;
}
#cssmenu.small-screen #menu-button {
 	background: url("../images/menu.png") no-repeat scroll 97.5% center;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    text-transform: uppercase;
    width: 100%;
	padding: 14px;
	color:#FFFFFF;
	font-weight:normal;
}
#cssmenu.small-screen #menu-button.menu-opened {
    background-image: url("../images/menu-close.png");
	background-position: 97.5% center;
}
.small-screen .nav a:before {
	content: none;;
}
.small-screen .nav > li > a {
	line-height:20px;
}
#cssmenu.small-screen .submenu-button {
  position: absolute;
  z-index: 999;
  right: 0;
  top: 0;
  display: block;
  border-left: 1px solid rgba(120, 120, 120, 0.2);
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.nav-maincontainer .small-screen .nav #home {padding-left:0}
.nav-maincontainer .small-screen  .nav #brands b {display: none}
.small-screen .nav li > ul > li > a {
	border: none;
}
#cssmenu.small-screen ul ul .submenu-button {
  height: 32px;
  width: 32px;
}
#cssmenu.small-screen .submenu-button:after {
  position: absolute;
  top: 19px;
  right: 15px;
  width: 8px;
  height: 2px;
  display: block;
  background: #FFFFFF;
  content: '';
}
#cssmenu.small-screen ul ul .submenu-button:after {
  top: 16px;
  right: 12px;
}
#cssmenu.small-screen .submenu-button.submenu-opened:after {
  background: #ffffff;
}
#cssmenu.small-screen .submenu-button:before {
  position: absolute;
  top: 16px;
  right: 18px;
  display: block;
  width: 2px;
  height: 8px;
  background: #FFFFFF;
  content: '';
}
#cssmenu.small-screen ul ul .submenu-button:before {
  top: 13px;
  right: 15px;
}
#cssmenu.small-screen .submenu-button.submenu-opened:before {
  display: none;
}
#cssmenu.small-screen.select-list {
  padding: 5px;
}