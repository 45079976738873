#wishlist .extendedCart {
	float: right;
	margin-left: 5px;
	}
#wishlist .extendedDelete {
	float: right;
	}
#wishlist .buttons {
	text-align: right; 
	margin-top: 10px;
	}

#wishlist dl.pageresults {
	margin: 20px 0 0 0;
	padding: 0;
	}
	
#wishlist dd, #wishlist form {
	margin: 0;
	padding: 0;
	}

#wishlist fieldset {
	border: 1px solid #9a9a9a;
	margin: 0 0 10px 0;
	padding: 10px;
	}

#wishlist legend {
	border: 1px solid #9a9a9a;
	background-color: #abbbd3;
	padding: 5px;
	}

#wishlist label.block {
	display: block;
	color: #930;
	margin-top: 7px;
	}

#wishlist label.inline {
	display: inline;
	color: #930;
	}

#wishlist form.control, #wishlist form.moveto {
	margin: 0 0 30px 0;
	padding: 0;
	}

#wishlist form.control fieldset {
	border: 0;
	margin: 2px 0 1px 0;
	padding: 0;
	}

#wishlist form.control label {
	display: block;
	color: #000;
	margin-top: 0px;
	}

#wishlist form.control div.single {
	padding: 3px 5px;  
	}

#wishlist form.control div.multiple {
	padding: 3px 5px; 
	float: left; 
	}

#wishlist form.control div.multiple label {
	display: block;
	}

#wishlist div.clearleft {
	padding: 0; 
	margin: 0; 
	clear:left; 
	}

#wishlist .s {
	width: 50px;
	}

#wishlist .m {
	width: 100px;
	}

#wishlist .l {
	width: 175px;
	}

#wishlist .xl {
	width: 300px;
	}

#wishlist table.productlist {
	border: 1px solid #9a9a9a;
	}

#wishlist table.productlist tr.heading {
	height: 25px;
	background: #abbbd3;
/*	background-image: url(../images/tile_back.jpg);*/
	background-repeat: repeat-x;
	color: #fff;
	font-size: x-small;
	}
	
#wishlist .footnote {
	color: #666;
	font-size: x-small;
	padding-top: 0;
	margin-top: 20px;
	}

#wishlist table.productlist th {
	padding: 2px;
	}

#wishlist table.productlist th.right, #wishlist table.productlist th.center {
	text-align: center;
	}

#wishlist table.productlist td {
	border-top: 1px solid #9a9a9a;
	padding: 2px;
	}
	
#wishlist table.productlist td.odd-right {
	text-align: right;
	}
	
#wishlist table.productlist td.odd-center {
	text-align: center;
	}
	
#wishlist table.productlist td.even {
	background-color: #eff4f8;
	}
	
#wishlist table.productlist td.even-right {
	background-color: #eff4f8;
	text-align: right;
	}
	
#wishlist table.productlist td.even-center {
	background-color: #eff4f8;
	text-align: center;
	}

#wishlist div.tableheading {
	height: 25px;
	background: #abbbd3;
	/*background-image: url(../images/tile_back.jpg);*/
	background-repeat: repeat-x;
	color: #fff;
	font-size: x-small;
	font-weight: bold;
	border: 1px solid #9a9a9a;
	padding: 3px 6px 0px 6px;
	}

#wishlist div.wishlist-even {
	margin: 0px 0px 0px 0px;
	padding: 10px; 
	border: 1px solid #9a9a9a;
	border-top: 0px;
	background-color: #eff4f8;
	}

#wishlist div.wishlist-odd {
	margin: 0px 0px 0px 0px;
	padding: 10px; 
	border: 1px solid #9a9a9a;
	border-top: 0px;
	}

#wishlist img.productlist {
	margin: 0px 15px 15px 0px;
	float: left;
	}

#wishlist div.wishlistfields {
	margin: 10px 0px 0px 0px;
	padding: 10px 0px; 
	border-top: 1px dotted #9a9a9a;
	}

#wishlist ul {
	list-style: none none outside;
	padding-left: 0em;
	margin-left: 0px;
	margin-top: 0px;
	}

#wishlist ol {
	padding-left: 2.2em;
	margin-left: 0px;
	margin-top: 0px;
	text-indent: -0.4em;
	}

#wishlist ol li {
	line-height: 1.4em;
	}

#wishlist .inputrequirement {
	color: #379;
	}

#wishlist p.inputrequirement {
	text-align: right;
	}

#wishlist .notabene { 
	color: #379;
	font-weight: bold;
	}
	
#wishlist .price { 
	font-weight: bold;
	}

/*
label.tabbed-m {
	display: inline;
	float: left; 
	width: 7em; 
	text-align: left;
	clear: left;
	}
*/