/**
* @author Perfectus Web Designs
* @author website www.elegantdesignhub.com
* @copyright Copyright 2013-2014 Perfectus Web Designs
* CSS Document
*/
/*********/
body {
	background: none repeat scroll 0 0 transparent;
}

/* Theme Color */
a,
a:active,
a:visited,
#checkoutSuccessOrderLink>a,
#checkoutSuccessContactLink>a,
#checkoutSuccess a.cssButton.button_logoff,
#checkoutSuccess a,
#checkoutSuccess a:active,
#checkoutSuccess a:visited,
.product_title h3,
.accordian-header.active,
#product_name a,
#timeoutDefaultContent a,
table#cartContentsDisplay tr th,
#prevOrders .tableHeading th,
#accountHistInfo .tableHeading,
#reviewsWriteReviewer,
.bold.user_reviewer,
.reviews-list span.date,
#loginForm .buttonRow.back.important>a,
#logoffDefaultMainContent>a span.pseudolink,
.buttonRow.back.important>a,
.notfound_title,
#createAcctDefaultLoginLink>a,
#indexDefaultHeading,
#siteMapMainContent a,
#siteMapMainContent a:active,
#siteMapMainContent a:visited,
#unsubDefault a .pseudolink,
#unsubDefault a .pseudolink:active,
#unsubDefault a .pseudolink:visited,
.products_more:active,
.products_more:visited,
.products_more,
#centercontent-wrapper h1,
span.title,
.current-step,
.checkout-steps,
#productReviewLink>a,
#indexCategories #subcategory_names li:first-child,
#reviewsWriteProductPageLink>a,
#reviewsWriteReviewPageLink>a,
.review_content>p,
#productReviewsDefaultProductPageLink>a,
.gv_faq a,
.gv_faq a:visited,
.gv_faq a:active,
.alert>a,
.alert>a:active,
.alert>a:visited,
.reviews-list blockquote p a,
.reviews-list blockquote p a:active,
.reviews-list blockquote p a:visited,
.reviews-list blockquote h4 a,
.reviews-list blockquote h4 a:active,
.reviews-list blockquote h4 a:visited,
.nav-maincontainer #cssmenu.small-screen a {
	color: #505050;
}

a:hover,
#product_name a:hover,
#loginForm .buttonRow.back.important>a:hover,
.buttonRow.back.important>a:hover,
.cartBoxTotal,
#checkoutSuccessOrderLink>a:hover,
#checkoutSuccessContactLink>a:hover,
#checkoutSuccess a.cssButton.button_logoff:hover,
#subproduct_name>a:hover,
a.table_edit_button span.cssButton.small_edit:hover,
#accountDefault a:hover,
.allorder_text>a:hover,
#reviewsWriteProductPageLink>a:hover,
#reviewsWriteReviewPageLink>a:hover,
#productReviewLink>a:hover,
.buttonRow.product_price>a:hover,
#productReviewsDefaultProductPageLink>a:hover,
#searchContent a:hover,
#siteMapList a:hover,
.box_heading_style h1 a:hover,
.info-links>li:hover a,
#navBreadCrumb li a:hover,
.footer-toplinks a:hover,
.banner:hover .link:hover,
#cartContentsDisplay a.table_edit_button:hover,
#timeoutDefaultContent a:hover,
#logoffDefaultMainContent>a span.pseudolink:hover,
#createAcctDefaultLoginLink>a:hover,
#unsubDefault a .pseudolink:hover,
.review_content>p i.fa,
.gv_faq a:hover,
.alert>a:hover,
.reviews-list blockquote p a:hover,
.reviews-list blockquote h4 a:hover,
.header-container #nav li>ul>li:hover>a {
	color: #FF7260;
}

.navNextPrevList>a,
.login-buttons>a {
	color: #505050 !important;
}

#checkoutSuccess a:hover,
#siteMapMainContent a:hover,
.login-buttons>a:hover {
	color: #FF7260 !important;
}

td .simple-boxcontent h2,
#footer1-wrapper,
.topbar_links .menu>li:hover,
.topbar_links .menu>li#logoff:hover,
.topbar_links .menu>li#login:hover,
.productprice-wrapper .product_price {
	background-color: #505050;
}

#productAdditionalImages li:hover {
	border: 1px solid #505050;
}

.notfound_text {
	background: none repeat scroll 0 0 #505050;
}

.pageresult_bottom,
.pageresult_top,
.speciallisting_number_links {
	border-bottom: 1px solid #505050;
}

.owl-theme .owl-controls .owl-page:hover,
.addtocart-info .cart_quantity span:hover,
#indexCategories #subcategory_names li:hover,
#cssmenu.small-screen #menu-button,
#cssmenu.small-screen .submenu-button {
	background-color: #FF7260;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#centercontent-wrapper span.centerBoxHeading h2,
.categories-wrapper span.centerBoxHeading h2 {
	color: #505050;
}

/* Button */
.button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.readmore,
button,
.product-details,
.billto-shipto .details,
span.details-button input.details-button,
.control-buttons button.default,
#shoppingCartDefault .buttonRow,
#indexCategories #subcategory_names li,
.change_address>a,
#pageThree .buttonRow.back>a,
#pageFour .buttonRow.back>a,
#pageTwo .buttonRow.back>a,
#topbar-wrapper,
#sticky-header-wrapper .topbar_links a,
#discountcouponInfo .content .buttonRow.forward>a {
	background: none repeat scroll 0 0 #505050;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.readmore:hover,
button:hover,
.billto-shipto .details:hover,
.profile a:hover,
#shoppingCartDefault .buttonRow:hover,
.change_address:hover,
.change_address>a:hover,
#pageThree .buttonRow.back>a:hover,
#pageFour .buttonRow.back>a:hover,
#pageTwo .buttonRow.back>a:hover,
#discountcouponInfo .content .buttonRow.forward>a:hover {
	background-color: #FF7260;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

/*Pagination*/
.pagination-style .current,
.pagination-style a:hover {
	background-color: #FF7260;
}

.pagination-style a {
	background-color: #505050;
}

/*Sideboxes*/
.rectangle-list a:before {
	background-color: #505050;
}

#left-column h3,
#right-column h3 {
	background-color: #505050;
	border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

#right-column #categories li:hover a,
#left-column #categories li:hover a,
#left-column #cartBoxListWrapper li:hover>a,
#right-column #cartBoxListWrapper li:hover>a {
	border-left: 4px solid #FF7260;
	color: #FF7260;
}

.rectangle-list a:hover:before {
	background-color: #FF7260;
}

#right-column li a,
#left-column li a,
.sideBoxContentItem a,
.product_sideboxname>a,
#right-column li a:active,
#left-column li a:active,
.sideBoxContentItem a:active,
.product_sideboxname>a:active,
#right-column li a:visited,
#left-column li a:visited,
.sideBoxContentItem a:visited,
.product_sideboxname>a:visited {
	color: #505050;
}

#right-column li a:hover,
#left-column li a:hover,
.sideBoxContentItem a:hover,
.product_sideboxname>a:hover,
.sidebox_price .productSpecialPriceSale,
.sidebox_price .productSalePrice,
.sidebox_price .single_price,
.sidebox_price .productSpecialPrice,
.sidebox_price .productPriceDiscount,
#left-column .leftBoxHeading a:hover,
#right-column .rightBoxHeading a:hover,
#reviewsContent>a:hover {
	color: #FF7260;
}

.rectangle-list a:hover:after {
	left: -.5em;
	border-left-color: #FF7260;
}

#custom-content-wrapper .aboutus_heading {
	border-left: 5px solid #505050;
}

.navbar .btn-navbar,
.addtocart-info .cart_quantity span {
	background-color: #505050;
}

.custom_links.topbar_links.grid-40 li.arrow-down {
	background-color: rgba(0, 0, 0, 0.2);
}

.social_bookmarks li a:hover {
	background-color: #505050;
}

a:hover {
	text-decoration: none;
}

.header-container .header .header-top a:hover,
.header-container .header .header-content a:hover,
.item .product_image .hover_info a:active {
	background: none repeat scroll 0 0 #505050;
}

.header-container {
	background: none repeat scroll 0 0 #fc6471;
	border-top: 3px solid #505050;
}

.header-container .header .header-top .header-top-right ul.links li.last a:hover {
	background-color: #505050;
}

/*Nav*/
.nav-maincontainer #nav {
	background: none repeat scroll 0 0 #FF7260;
}

/* Menu Colors */
.header-container #nav>li.tab_active>a,
.header-container #nav>li:hover>a,
.header-container #nav>li>a:active,
.header-container .nav>li>a:hover,
.header-container .nav>li>a:focus {
	background-color: #505050;
}

.header-container .header a.toggleMenu {
	background-color: #505050;
}

.header-container .header a.toggleMenu:hover {
	background-color: #FF7260;
}

.header-container #nav ul li ul a,
.header-container .header #nav li ul a {
	color: #505050;
}

#nav li>ul {
	border-bottom: 2px solid #FF7260;
	z-index: 999;
}

.item .product_image .hover_info .zoom-button a,
.item .product_image .hover_info .detailbutton-wrapper a,
.centerBoxContentsAlsoPurch .product_image .hover_info .detailbutton-wrapper a,
.centerBoxContentsAlsoPurch .product_image .hover_info .zoom-button a,
#specialsListing .specialsListBoxContents .product_image .hover_info .detailbutton-wrapper a,
#specialsListing .specialsListBoxContents .product_image .hover_info .zoom-button a {
	color: #FF7260;
}

.hover_info h1,
.hover_info a:hover {
	background: none repeat scroll 0 0 #FF7260;
}

ul.tabs li.selected a,
ul.tabs li a:hover {
	border: 3px solid #FF7260;
	color: #FF7260;
	text-decoration: none;
}

ul.tabs:before {
	background: none repeat scroll 0 0 #FF7260;
}

.item .product_image .blind,
.centerBoxContentsAlsoPurch .product_image .blind,
.specialsListBoxContents .product_image .blind {
	background: none repeat scroll 0 0 #FF7260 !important;
	opacity: 0.95;
}

.item .product_image .hover_info a:hover,
.centerBoxContentsAlsoPurch .product_image .hover_info a:hover,
.specialsListBoxContents .product_image .hover_info a:hover,
#specialsListing .specialsListBoxContents .product_image .hover_info .detailbutton-wrapper a:hover,
#specialsListing .specialsListBoxContents .product_image .hover_info .zoom-button a:hover {
	background-color: #505050;
	color: #FAFAFA;
}

.centerBoxWrapper .owl-theme .owl-controls .owl-buttons div:hover {
	background-color: #505050;
}

/*Product Details*/
.product-name-desc .product_name a:hover {
	color: #FF7260;
}

#whatsNew .centerBoxContentsNew.centeredContent .product_price,
#featuredProducts .centerBoxContentsFeatured.centeredContent .product_price,
#specialsDefault .centerBoxContentsSpecials.centeredContent .product_price,
#specialsListing .specialsListBoxContents .product_price,
#alsopurchased_products .product_price,
#upcomingProducts .product_price,
.productListing-data .product_name>a:hover,
.newproductlisting .product_name>a:hover,
.productlisting_price .productSpecialPriceSale,
.productlisting_price .productSalePrice,
.productlisting_price .single_price,
.productlisting_price .productSpecialPrice,
.productlisting_price .productPriceDiscount {
	color: #FF7260;
}

.product-name-desc .product_name>a,
.product-name-desc .product_name>a:active,
.product_name>a:visited,
.productListing-data .product_name>a,
.newproductlisting .product_name>a {
	color: #505050;
}

.product-name-desc .product_name a:hover {
	color: #FF7260;
}

.navNextPrevList>a:hover {
	color: #FF7260 !important;
}

/*Brands*/
.brands-wrapper h2,
.category-slideshow-wrapper h2,
.box_heading h2 {
	color: #FF7260;
	border: 3px solid #FF7260;
}

#additionalimages-slider .owl-controls .owl-prev:hover,
#additionalimages-slider .owl-controls .owl-next:hover,
#main-slideshow .owl-controls .owl-prev:hover,
#main-slideshow .owl-controls .owl-next:hover,
.brands-slider .owl-controls .owl-prev:hover,
.brands-slider .owl-controls .owl-next:hover {
	background-color: #505050;
}

.brands-wrapper header:before,
.category-slideshow-wrapper header:before,
.box_heading header:before {
	background: none repeat scroll 0 0 #FF7260;
}

#bar {
	background: none repeat scroll 0 0 #505050;
}

/*Footer Top*/
.newsletter #mc_embed_signup input.button {
	background-color: #FF7260;
}

.newsletter #mc_embed_signup input.button:hover {
	background-color: #505050;
}

#contactForm-widget input[type="submit"] {
	background-color: #505050;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#contactForm-widget input[type="submit"]:hover {
	background-color: #FF7260;
}

.footer-top-wrapper {
	border-bottom: 5px solid #FF7260;
	background: none repeat scroll 0 0 #fc6471;
}

/*Footer*/
.contact-us ul li:hover .fa,
.about-us .address:hover .fa {
	background-color: #FF7260;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.mail>a:hover {
	color: #FF7260;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.copyright .top:hover {
	background-color: #FF7260;
	background-position: 100% 100%;
}

/*Sticky Header */
.sticky-header-wrapper {
	background: none repeat scroll 0 0 #FF7260;
}

.sticky-header-wrapper .topbar_links a:hover {
	background: none repeat scroll 0 0 #505050;
}

/*Product Info*/
#productGeneral .productprice-amount .productSpecialPrice,
#productGeneral .productprice-amount .productPriceDiscount,
.product_price.total span.total_price,
#reviewsWrite .productprice-amount,
#reviewsInfoDefault .productprice-amount,
#reviewsDefault .productprice-amount,
.single_price {
	color: #FF7260;
}

#navBreadCrumb li,
#navBreadCrumb li a,
#navBreadCrumb li:last-child a,
#navBreadCrumb li:last-child a:active,
#navBreadCrumb li:last-child a:visited {
	color: #505050;
}

.breadcrumb-current,
#navBreadCrumb li:last-child a:hover {
	color: #FF7260;
}

.productListing-odd:hover,
.productListing-even:hover,
.newproductlisting:hover {
	border-bottom: 1px solid #FF7260;
}

#centercontent-wrapper header:before {
	background: none repeat scroll 0 0 #FF7260;
}

#centercontent-wrapper header>h1 {
	border: 3px solid #FF7260;
	color: #FF7260;
}

/* Menu Border */
#cssmenu ul ul li.has-sub:before {
	border-left: 4px solid #505050;
}

#cssmenu ul ul>li.has-sub:hover:before {
	border-left: 4px solid #FF7260;
}

/*Cart Table*/
.cartTableHeading,
#cartSubTotal {
	color: #505050;
}

.add_title {
	background: none repeat scroll 0 0 #FF7260;
}

a>.checkout-steps:hover {
	border: 3px solid #FF7260;
	color: #FF7260;
	text-decoration: none;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.review-links>.buttonRow {
	background: none repeat scroll 0 0 #505050;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.review-links>.buttonRow:hover {
	background: none repeat scroll 0 0 #FF7260;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

/*Top banner*/
.top-banner {
	border-bottom: 1px solid #505050;
}

.top-banner h3,
.top-banner p {
	color: #505050;
}

.top-banner:hover {
	border-bottom: 1px solid #FF7260;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.top-banner:hover h3 {
	color: #FF7260;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

/*New Categories Sidebox*/
#nav-cat li.submenu,
#nav-cat ul {
	background-color: #FF7260;
}

#nav-cat li:hover,
#nav-cat li.submenu:hover {
	background-color: #505050;
}

/* Theme Color Ends*/
.main-breadcrumb {
	display: none
}


/*********/
html {}

body {
	font-family: 'Telex', Arial, Helvetica;
	color: #404040;
	font-size: 85%;
	margin: 0;
	line-height: 1.322;
}

TEXTAREA {
	display: block;
	width: 100%;
}

ul {
	list-style: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
textarea,
select {
	border: 1px solid #c2c2c2;
	color: #404040;
	line-height: 20px;
	margin: 1px 0 3px;
	min-height: 20px;
	padding: 4px 5px 3px;
	font-size: 0.95em;
	transition: all .3s ease-in 0;
	-moz-transition: all .3s ease-in 0;
	-webkit-transition: all .3s ease-in 0;
	-o-transition: all .3s ease-in 0;
	-ms-transition: all .3s ease-in 0
}

input[type="text"]:hover,
input[type="password"]:hover,
input[type="email"]:hover,
input[type="url"]:hover,
textarea:hover,
select:hover {
	border: 1px solid #a9a9a9;
	transition: all .3s ease-in 0;
	-moz-transition: all .3s ease-in 0;
	-webkit-transition: all .3s ease-in 0;
	-o-transition: all .3s ease-in 0;
	-ms-transition: all .3s ease-in 0;
}

textarea {
	overflow: auto
}

.button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.readmore,
button {
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	font-size: 1.2em;
	padding: 10px 16px;
	;
	border-bottom: 3px solid rgba(0, 0, 0, 0.2);
	border-top: none;
	border-right: none;
	border-left: none;
	text-decoration: none;
	width: auto !important;
	min-height: 45px;
	transition: all 0.3s ease-in 0s;
	-moz-transition: all 0.3s ease-in 0s;
	-webkit-transition: all 0.3s ease-in 0s;
	-o-transition: all 0.3s ease-in 0s;
	-ms-transition: all 0.3s ease-in 0s;
	background: none repeat scroll 0 0 #404040;
}

img {
	max-width: 100%;
}

a,
a:hover {
	outline: none;
	font-weight: normal;
	transition: all 0.3s ease-in-out 0s !important;
	-moz-transition: all 0.3s ease-in-out 0s !important;
	-webkit-transition: all 0.3s ease-in-out 0s !important;
	-o-transition: all 0.3s ease-in-out 0s !important;
	-ms-transition: all 0.3s ease-in-out 0s !important;
}

a:active,
a:visited {
	outline: none;
}

#right-column {
	vertical-align: top;
}

#left-column,
#right-column {
	text-shadow: none;
}

#left-column h3 label,
#right-column h3 label {
	margin-bottom: 0px;
}

.centerBoxWrapper {
	margin-bottom: 10px;
}

#right-column .select[multiple],
select[size] {
	height: auto !important;
}

#content-wrapper table td {
	border-bottom: none;
}

#centercontent-wrapper {
	position: relative;
	float: left;
}

.single-column {
	right: 0;
	width: 100%;
}

a:hover,
a:active {
	outline: 0 none;
}

.centerBoxWrapper,
.tabTable {
	padding: 0px;
	height: 1%;
	/*do not remove- IEhack*/
	/*line-height:1.4;*/
}

.leftBoxContainer,
.rightBoxContainer {
	width: 100% !important;
	margin-bottom: 30px;
	float: left;
}

.leftBoxContainer,
.rightBoxContainer {
	box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.4);
}

h3.rightBoxHeading,
h3.leftBoxHeading {
	margin: 0;
}

.sideBoxContent ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sideBoxContent ul li {
	margin-bottom: 0;
}

#reviewsHeading>a {
	padding-left: 0 !important;
}

table td,
.centerBoxWrapperContents table td {
	padding: 0px;
}

#contentarea-wrapper .grid-64 {
	width: 63%;
}

#bannerbox2Content a,
#bannerboxallContent a,
#bannerboxContent a,
#whatsnewHeading>a,
.sideBoxContentItem a,
.reviewsContent a,
#twittercachedsideboxContent a,
#searchContent a {
	padding-left: 0px !important;
	padding-top: 1px;
}

.centeredContent,
TH,
#cartEmptyText,
#cartBoxGVButton,
#navNextPrevWrapperTop,
#navNextPrevWrapperBottom,
#navCatTabsWrapper,
#navEZPageNextPrev,
#bannerOne,
#bannerTwo,
#bannerThree,
#bannerFour,
#bannerFive,
#bannerSix,
#siteinfoLegal,
#siteinfoCredits,
#siteinfoStatus,
#siteinfoIP,
.center,
.cartRemoveItemDisplay,
.cartQuantityUpdate,
.cartQuantity,
.cartTotalsDisplay,
#cartBoxGVBalance,
.accountQuantityDisplay,
LABEL#textAreaReviews,
#productMainImage,
#reviewsInfoDefaultProductImage,
#productReviewsDefaultProductImage,
#reviewWriteMainImage,
.centerBoxContents,
.specialsListBoxContents,
.categoryListBoxContents,
.additionalImages,
.centerBoxContentsSpecials,
.centerBoxContentsAlsoPurch,
.centerBoxContentsFeatured,
.centerBoxContentsNew,
.gvBal,
.attribImg,
.specialsListBoxContents {
	text-align: center;
}

/*CSS for login page */
#loginForm FIELDSET {
	padding: 0 0 1.5em 0;
}

FIELDSET {
	border: none !important;
}

LEGEND {
	font-weight: bold;
	padding: 0.3em 0.3em 0.3em 0;
}

.return_customer LEGEND {
	padding: 0 0 0.5em 0;
}

LABEL.checkboxLabel,
LABEL.radioButtonLabel {
	font-size: 0.95em;
	margin: 0.2em 0.5em;
	font-weight: bold;
}

#createAccountForm fieldset legend {
	font-size: 1.2em;
}

#createAccountForm fieldset legend {
	font-size: 1.2em;
}

/*CSS For Breadcrumb*/
#breadcrumb-wrapper .breadcrumb-title {
	color: #FFFFFF;
	float: left;
	font-size: 36px;
	text-align: center;
	width: 100%;
	display: none;
}

/*CSS For Breadcrumb End*/
.productinfo-wrapper {
	display: block;
	float: left;
	text-align: center;
	width: 100%;
}

.product_name,
#whatsNew .centerBoxContentsNew.centeredContent .product_price,
#featuredProducts .centerBoxContentsFeatured.centeredContent .product_price,
#specialsDefault .centerBoxContentsSpecials.centeredContent .product_price,
#specialsListing .specialsListBoxContents .product_price,
#alsopurchased_products .product_price,
#upcomingProducts .product_price {
	font-size: 1.1em;
	text-align: left;
	float: left;
	width: 100%;
	margin: 10px 0 0;
	padding: 0 20px;
	text-align: center;
}

.productListing-data .product_name,
.newproductlisting .product_name {
	text-align: left;
}

.product_name {
	margin: 1em 0 0;
}

.product-name-desc {
	float: left;
	width: 100%;
	transition: all 0.5s ease-in-out 0s;
	-moz-transition: all 0.5s ease-in-out 0s;
	-webkit-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
	-ms-transition: all 0.5s ease-in-out 0s;
}

.product_price {
	text-align: left;
	display: inline-block;
	width: 100%;
}

/*width For button*/
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
	width: auto;
}

#shoppingcartHeading>a {
	padding: 0 !important;
}

#shoppingcart h3,
#reviews h3 {
	padding: 13px 13px 13px 10px;
}

.cartBoxTotal {
	font-size: 1.2em;
	float: left;
	text-align: center;
	width: 100%;
	padding: 1em 0;
}

#ezPageBoxList li {
	margin: 0;
	padding: 0.2em 0;
	text-align: center;
}

#contentMainWrapper,
#logoWrapper,
#ezPageBoxList,
#ezPageBoxList ul,
#popupAdditionalImage,
#popupImage {
	float: left;
	margin: 0 6px 10px;
	padding: 0;
	width: 94%;
}

.categoryIcon,
.information,
#checkoutShippingContentChoose {
	margin-bottom: 15px;
}

.product-name-desc .normalprice,
.product-name-desc .productSpecialPriceSale {
	color: #595959;
}

.normalprice,
.productSpecialPriceSale {
	text-decoration: line-through;
	color: #595959;
	font-size: 0.7em;
}

.sidebox_price .normalprice,
.sidebox_price .productSpecialPriceSale {
	color: #595959;
}

#indexDefaultMainContent {
	margin-top: -5px;
}

#contentColumnMain,
#navColumnOne,
#navColumnTwo,
.centerBoxContents,
.specialsListBoxContents,
.categoryListBoxContents,
.additionalImages,
.centerBoxContentsSpecials,
.centerBoxContentsAlsoPurch,
.centerBoxContentsFeatured,
.centerBoxContentsNew,
.alert-info {
	vertical-align: top;
}

#contentarea-wrapper #centercontent-wrapper .alsoPurchased .centerBoxWrapperContents table td {
	background-color: #FFFFFF;
	border: 1px solid #E1E1E1;
	padding: 5px;
}

#contentarea-wrapper #centercontent-wrapper .alsoPurchased .centerBoxWrapperContents table td.no-border {
	border: none;
}

#contentarea-wrapper #centercontent-wrapper .navNextPrevWrapper {
	margin: 10px 0 0;
}

#contentarea-wrapper #centercontent-wrapper .alsoPurchased .centerBoxWrapperContents .centerBoxContentsAlsoPurch {
	margin: 0 !important;
	background-color: #E1E1E1;
	padding: 4%;
	width: 92% !important;
}

#contentarea-wrapper #centercontent-wrapper .alsoPurchased .centerBoxWrapperContents img {
	height: 100px;
}

/*For full width layout padding */
#ezPagesHeading {
	padding-bottom: 14px;
	margin-bottom: 14px;
	padding-top: 7px;
	border-bottom: 1px solid #E9E8E8;
}

#contactUsForm .alert-info.forward {
	margin-bottom: 15px;
}

#loginDefault .inputlogin,
#checkoutNewAddress input[type='text'],
#checkoutNewAddress SELECT,
#accountEditDefault input[type='text'],
#accountPassword input[type="password"],
#addressBookProcessDefault input[type='text'],
#addressBookProcessDefault SELECT {
	color: #505050;
	margin-bottom: 2px;
	width: 40%;
	margin-bottom: 7px;
}

.alert-text,
.information .alert-text,
.msgalert {
	color: #595959;
	font-style: italic;
}

#loginForm .back {
	padding-left: 22px;
	line-height: 44px;
}

.login-emailaddress,
#loginForm .buttonRow {
	float: left;
}

LABEL.inputLabel {
	float: left;
	margin-top: 7px;
	margin-right: 10px;
}

.login-emailaddress .inputLabel {
	width: 110px;
	margin-top: 5px;
}

.login-password {
	float: left;
	margin-left: 30px;
}

.login-password .inputLabel {
	width: 80px;
	margin-top: 5px;
}

#createAccountForm .alert-info.forward {
	margin-bottom: 10px;
}

input[type="radio"],
input[type="checkbox"] {
	cursor: pointer;
	line-height: normal;
	margin: 6px 0;
}

#subproduct_name img {
	height: auto;
	margin-bottom: 25px;
}

/*for bck button */
#ezPageDefault h4 {
	text-transform: none;
	margin-top: 5px;
}

#reviewsInfoDefaultProductImage,
#productReviewsDefaultProductImage,
#reviewWriteMainImage {
	margin: 0 4% 1% 0;
}

#productListing {
	margin: 10px 0 14px;
}

#cartAdd strong,
.add_title {
	font-size: 1.2em;
	;
}

.product_info,
.product_description,
.addtocart-info,
.product_quantity,
.product_review,
.navNextPrevWrapper,
.alsoPurchased,
#productAttributes {
	float: left;
	width: 100%;
}

span.title {
	display: block;
	font-size: 1.5em;
	font-weight: normal;
	margin: 0 0 14px;
	padding-bottom: 9px;
	border-bottom: 1px solid #E9E9E9;
}

.product_review span.title {
	margin-bottom: 15px;
}

.alsoPurchased span.title,
#centercontent-wrapper #productGeneral {
	margin: 0;
}

.cssButton.button_reviews {
	width: 100%;
}

.navNextPrevCounter {
	margin: 0em;
	font-size: 0.9em;
}

#navMainWrapper,
#navSuppWrapper,
#navCatTabsWrapper {
	margin: 0em;
	font-weight: normal;
	height: 1%;
	width: 100%;
}

span.bold {
	display: block;
	font-size: 0.95em;
	font-weight: 700;
}

.review_img {
	text-align: center;
	float: left;
}

#checkoutConfirmDefault .productprice-amount {
	font-size: 15px;
	width: 50%;
}

.cartQuantity input[type='text'] {
	text-align: center;
	vertical-align: middle;
}

#shoppingCartDefault .buttonRow a,
#checkoutShipping .change_address a,
#checkoutPayment .change_address a,
#checkoutConfirmDefault .change_address a,
#addressBookDefault .change_address a span,
#accountEditDefault .change_address a span,
#accountHistoryDefault .change_address a,
#accountHistInfo .change_address a,
#createAcctSuccess .change_address a,
#unsubDefault .change_address a {
	color: #FFFFFF;
}

#shoppingCartDefault .buttonRow,
.change_address>a,
#pageThree .buttonRow.back>a,
#pageFour .buttonRow.back>a,
#pageTwo .buttonRow.back>a,
#discountcouponInfo .content .buttonRow.forward>a {
	background: none repeat scroll 0 0 #404040;
	border-color: -moz-use-text-color -moz-use-text-color rgba(0, 0, 0, 0.2);
	border-style: none none solid;
	border-width: medium medium 3px;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	font-size: 1.2em;
	padding: 10px 16px;
	text-decoration: none;
	width: auto !important;
	min-height: 45px;
}

.instructions p:nth-child(2) {
	font-style: italic;
}

#prevOrders .user-accountinfo,
.user-accounthistory {
	text-align: center;
}

#accountDefault h2,
#addressBookDefault h2,
caption #orderHistoryDetailedOrder,
caption #orderHistoryStatus {
	padding-left: 0px;
}

#checkoutConfirmDefault #orderTotals {
	float: right;
	width: 40%;
}

#checkoutConfirmDefault .product_price.total strong {
	float: left;
	width: 63%;
}

#checkoutConfirmDefault div.total_price {
	float: left;
	width: 37%;
}

#orderTotals .lineTitle {
	float: left;
	width: 60%;
}

#orderTotals .amount {
	float: left;
	width: 40%;
	font-weight: bold;
}

#shippingEstimatorContent label.inputLabel {
	width: 100px;
}

#advSearchDefault .centeredContent {
	text-align: left;
}

.attribImg,
.attributesComments {
	display: none;
}

.attribImg {
	margin: 0.3em 0;
	width: 20%;
}

.productprice-wrapper>img,
.product_price>img {
	min-height: auto;
	margin-left: 10px;
	float: right;
}

h4.optionName {
	width: 10.8%;
	float: left;
	margin-top: 2px;
}

#addressBookDefault .productinfo-leftwrapper .change_address {
	margin-right: 3px;
}

.productprice-wrapper>a {
	float: left;
	margin-top: 15px;
	width: 35%;
}

#addressBookDefault .productinfo-leftwrapper>a {
	display: none;
}

#productQuantityDiscounts>table {
	border: 1px solid;
	border-collapse: collapse;
	width: 100%;
}

.indexDefaultHeading {
	/*background: url("../images/icons/line.png") repeat-x scroll 0 bottom transparent;*/
	padding: 0 0 13px;
}

.navbar .nav {
	width: 100%;
}

.jsn-header .container,
.navbar .container {
	width: auto !important;
}

h2#gvFaqDefaultSubHeading,
#gvFaqDefaultMainContent {
	padding-left: 0px;
	background: none repeat scroll 0 0 transparent;
	color: #595959;
}

.jsn-header .container,
.navbar .container {
	width: auto !important;
	display: block;
}

/* For Products */
#whatsNew .centerBoxWrapperContents .centerBoxContentsNew,
#specialsDefault .centerBoxWrapperContents .centerBoxContentsSpecials,
#featuredProducts .centerBoxWrapperContents .centerBoxContentsFeatured {
	width: 95.4% !important;
	margin-left: 5px;
	margin-bottom: 2px;
}

#whatsNew .centerBoxWrapperContents,
#specialsDefault .centerBoxWrapperContents,
#featuredProducts .centerBoxWrapperContents {
	margin-bottom: -10px;
}

#centercontent-wrapper .item-page h2 {
	display: block;
	margin-bottom: 10px;
	padding-bottom: 10px;
	padding-left: 0;
	font-size: 1.5em;
	;
}

em {
	font-style: normal !important;
}

/* short */
.item-page .thindottedheader a.button {
	color: #FFFFFF;
}

#siteMap .content,
#gvFaqDefault .content,
#discountcouponInfo .content,
#privacy .content,
#conditions .content,
#shippingInfoMainContent {
	left: 0%;
	position: relative;
}

/* Opera */
.opera #slideshow-wrapper {
	min-height: 450px;
}

.opera .detailbutton-image a {
	width: 78px;
}

.pagination-style a {
	text-shadow: none !important;
	padding: 5px 10px;
	text-decoration: none;
	border: 1px solid #E9E9E9;
	/*color: #FFFFFF !important;*/
}

.pagination-style .fa {
	margin: 0;
}

.pagination-style .current {
	color: #FFFFFF;
	padding: 5px 10px;
}

.cat-count {
	margin-left: 5px;
}

.pagination-style a>img {
	position: relative;
	top: 3px;
}

#createAccountForm LABEL.inputLabel,
#accountEditDefault LABEL.inputLabel {
	width: 250px;
}

.content_box fieldset {
	padding: 0;
}

.next-prev {
	display: inline-block;
	width: 100%;
}

#accountNotifications .next-prev {
	margin-top: 20px;
}

.next-prev .buttonRow {
	float: left;
	margin-right: 10px;
}

.alert-text,
.information .alert-text,
.msgalert,
.alertrequired {
	color: #FF4444;
	font-style: italic;
}

/*Products homepage */
#specialsListing>ul {
	margin: 15px 0 0;
	padding: 0;
}

#whatsNew .item,
#featuredProducts .item,
#specialsDefault .item,
#specialsListing li,
#alsopurchased_products .centerBoxContentsAlsoPurch,
#upcomingProducts li {
	border-bottom: 1px solid #E9E9E9;
	display: inline-block;
	box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.4);
	margin: 0 15px 3px;
	overflow: inherit;
	position: relative;
	float: left;
	padding: 10px 10px 20px;
	background: none repeat scroll 0 0 #F9F9F9;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-webkit-backface-visibility: hidden;
}

#indexDefault #specialsDefault.centerBoxWrapper {
	margin: 30px 0 0
}

#featuredProducts #featured-slider-inner .item {
	margin: 13px;
}

#whatsNew .owl-wrapper-outer:hover .item,
#featuredProducts .owl-wrapper-outer:hover .item,
#specialsDefault .owl-wrapper-outer:hover .item,
#specialsListing ul:hover li,
#alsopurchased_products .owl-wrapper-outer:hover .centerBoxContentsAlsoPurch,
#upcomingProducts .owl-wrapper-outer:hover .item {
	opacity: 0.25;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-webkit-backface-visibility: hidden;
}

#whatsNew .owl-wrapper-outer:hover .item:hover,
#featuredProducts .owl-wrapper-outer:hover .item:hover,
#specialsDefault .owl-wrapper-outer:hover .item:hover,
#specialsListing ul:hover li:hover,
#alsopurchased_products .owl-wrapper-outer:hover .centerBoxContentsAlsoPurch:hover,
#upcomingProducts .owl-wrapper-outer:hover .item:hover,
#categories-content-wrapper ul:hover li:hover {
	opacity: 1;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-webkit-backface-visibility: hidden;
	background: none repeat scroll 0 0 #F5F5F5;
}

#specialsListing li {
	width: 29.75%;
	-webkit-backface-visibility: hidden;
	margin: 15px;
}

.homepage_v3 #specialsListing li {
	width: 29.59%
}

#indexCategories #whatsNew li:nth-child(3n+3),
#indexCategories #featuredProducts li:nth-child(3n+3),
#indexCategories #specialsDefault li:nth-child(3n+3),
#indexProductList #whatsNew li:nth-child(3n+3),
#indexProductList #featuredProducts li:nth-child(3n+3),
#indexProductList #specialsDefault li:nth-child(3n+3),
#alsopurchased_products li:nth-child(3n+3) {
	margin-right: 0;
}

#indexCategories #featuredProducts li:last-child,
#indexProductList #featuredProducts li:last-child {
	display: none;
}

.zoom-button .fa,
.detailbutton-wrapper .fa {
	margin: 0;
}

#whatsNew,
#featuredProducts,
#specialsDefault,
#upcomingProducts {
	float: left;
	margin: 0 0 30px;
	width: 100%;
}

#indexDefault .centerBoxWrapper:last-child {
	margin: 0;
}

.productSpecialPriceSale,
.productSalePrice,
.normalprice,
.productSpecialPrice,
.productPriceDiscount {
	margin-right: 5px;
}

.productPriceDiscount {
	margin-right: 0;
}

#indexCategories #whatsNew,
#indexCategories #featuredProducts,
#indexCategories #specialsDefault {
	margin-top: 0px;
	overflow: hidden;
}

#indexProductList #whatsNew,
#indexProductList #featuredProducts,
#indexProductList #specialsDefault {
	margin-top: 20px;
	overflow: hidden;
}

.centerBoxHeading .fa {
	margin: 0 0 0 10px;
}

.border-bg {
	float: left;
}

.productprice-wrapper .product_price {
	padding: 5px;
	bottom: 0;
	padding: 5px;
	position: absolute;
}

p.short-description {
	margin: 0;
}

/* Create Account Success */
#createAcctSuccess .buttonRow.forward.change_address {
	display: inline-block;
}

/* Product Detail Page */
#productAdditionalImages li {
	display: inline-block;
	list-style: none outside none;
	margin-bottom: 4px;
	padding: 5px;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	border: 1px solid #E9E9E9;
}

.additionalImages.centeredContent img {
	height: auto;
	width: 75px;
}

#productMainImage {
	display: inline-block;
}

.product_info .product_image {
	text-align: center;
	width: 100%;
}

#additionalimages-slider {
	border-top: 1px solid #E9E9E9;
	padding: 15px 0 0;
}

#upcomingProducts .product_image {
	width: 30%;
}

#whatsNew .product_image:after,
#featuredProducts .product_image:after,
#specialsDefault .product_image:after,
#specialsListing .product_image:after,
.centerBoxContentsAlsoPurch .product_image:after {
	content: "";
	display: block;
	margin: 1.9em auto 0;
	width: 150px;
	border-bottom: 1px dashed #E9E9E9;
	transition: all 0.6s ease-in-out 0s;
	-moz-transition: all 0.6s ease-in-out 0s;
	-webkit-transition: all 0.6s ease-in-out 0s;
	-o-transition: all 0.6s ease-in-out 0s;
	-ms-transition: all 0.6s ease-in-out 0s;
}

#upcomingProducts .product-name-desc {
	float: right;
	width: 65%;
}

#upcomingProducts .product_name {
	margin: 0;
}

#whatsNew .product_image img,
#featuredProducts .product_image img,
#specialsDefault .product_image img,
#specialsListing .product_image img,
.centerBoxContentsAlsoPurch .product_image img,
#upcomingProducts .product_image img {
	height: auto;
	width: 100%;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#productAdditionalImages {
	float: left;
	text-align: center;
	width: 100%;
	margin-top: 20px;
}

.imgLink {
	display: none;
}

#centercontent-wrapper .alsoPurchased>h1 {
	margin: 0;
}

#centercontent-wrapper .product_title h1#productName {
	margin-bottom: 0;
}

.product_info {
	margin-bottom: 35px;
	margin-top: 15px;
}

.product_info_tab {
	float: left;
	width: 100%;
	margin: 15px 0;
}

.alsoPurchased {
	margin: 35px 0 0;
}

.tabcontents #description>p {
	margin: 0;
}

#product_info_display .product_price {
	border-bottom: 1px solid #E9E9E9;
	margin: 15px 0 0;
	padding-bottom: 15px;
}

#product_info_display .grid-32.noleft-margin.noright-margin {
	float: right;
}

.productprice-amount {
	font-size: 1.3em;
	font-weight: normal;
	width: 100%;
}

.product_quantity {
	border-bottom: 1px solid #E9E9E9;
	line-height: 25px;
	padding-bottom: 13px;
}

.product_info_accordian .product_description,
.product_review {
	margin: 0 0 10px;
	padding: 0
}

#alsopurchased_products {
	float: left;
}

.centerBoxContentsAlsoPurch {
	list-style: none outside none;
}

/* Cart Button */
.addtocart-info .cart_quantity {
	display: inline-block;
	float: left;
	width: auto;
	position: relative;
}

.cart_quantity>strong {
	display: inline-block;
	float: left;
	line-height: 44px;
	margin-right: 1em;
}

.addtocart-info .cart_quantity span.minus {
	top: 22px;
}

.addtocart-info .cart_quantity span {
	color: #FFFFFF;
	cursor: pointer;
	font-size: 1.7em;
	line-height: 22px;
	position: absolute;
	text-align: center;
	width: 19px;
	top: 0;
}

.addtocart-info .cart_button {
	float: left;
	margin: 0 0 0 2.4em;
	width: auto;
}

.addto_cart .cart_quantity strong {
	float: left;
	margin-right: 10px;
	margin-top: 9px;
}

.product_info_accordian {
	background: #F2F2F2;
	float: left;
	padding: 10px;
	width: 96%;
	box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.1);
	margin-left: 3px;
}

/*Accordian */
.accordian-header {
	cursor: pointer;
	float: left;
	font-weight: normal;
	height: 35px;
	line-height: 35px;
	text-align: left;
	width: 93.6%;
	display: block;
	text-decoration: none;
}

h4.accordian-header {
	font-size: 1.8em;
	margin: 0;
	padding: 0 0 0 4%;
	width: 100%;
}

.accordian-header a {
	color: #FFFFFF;
}

.accordian-header a:hover {
	color: #FFFFFF;
}

.accordian-content {
	border-bottom: 1px solid #909090;
	clear: both;
	padding: 2%;
	width: 100%;
	background: #FFFFFF;
	font-size: 1.4em;
}

.accordian-header.active {
	background: url("../images/plus-minus.png") no-repeat scroll 5px -91px rgba(255, 255, 255, 0.3) !important;
}

.accordian-header {
	background: url("../images/plus-minus.png") no-repeat scroll 5px 7px rgba(255, 255, 255, 0.3) !important;
}

.contentText.accordian-content>p {
	margin: 0;
}

/* Product Attributes */
#productAttributes>.wrapperAttribsOptions,
.addtocart-info {
	border-bottom: 1px solid #E9E9E9;
	float: left;
	margin-top: 15px;
	padding: 0 0 15px;
	width: 100%;
}

.addthis_toolbox {
	float: left;
	margin-top: 15px;
	width: 100%;
}

#contentarea-wrapper #centercontent-wrapper .productinfo-rightwrapper .navNextPrevWrapper.centeredContent {
	float: left;
	margin: 20px 0 0;
	text-align: left;
}

#contentarea-wrapper .navNextPrevWrapper .navNextPrevList .button_return_to_product_list {
	font-size: 19px;
	position: relative;
	top: 2px;
}

.navNextPrevList i.fa {
	font-size: 0.8em;
	line-height: 0;
	margin: 0;
}

#twttrHubFrameSecure {
	height: 0 !important;
	position: relative !important;
}

#twttrHubFrame {
	height: 0 !important;
	position: relative !important;
}

.product_attributes {
	width: 100%;
	float: left;
}

.attribute_label {
	float: left;
	font-size: 1.2em;

}

.attribute_options>input[type="radio"] {
	margin-right: 8px;
}

.attribute_options>label {
	font-size: 1em;
	margin-right: 10px;
}

/* Left Right Box */
#left-column h3,
#right-column h3 {
	color: #FFFFFF;
	font-size: 1.2em;
	line-height: 25px;
	padding: 10px;
	text-transform: uppercase;
}

#left-column .leftBoxContainer a,
#right-column .rightBoxContainer a {
	font-size: 0.95em;
	;
	line-height: 18px;
	padding: 8px 0px;
	text-decoration: none;
	word-wrap: break-word;
	display: block;
}

#left-column .leftBoxContainer .product_sideboxname>a,
#right-column .rightBoxContainer .product_sideboxname>a {
	font-size: 1em;
}

#left-column .leftBoxContainer #categoriesContent a,
#right-column .rightBoxContainer #categoriesContent a,
#right-column #cartBoxListWrapper li>a,
#left-column #cartBoxListWrapper li>a {
	border-left: 4px solid #F9F9F9;
	line-height: 35px;
	padding: 0 0 0 10px;
}

#left-column .leftBoxHeading a,
#right-column .rightBoxHeading a {
	float: right;
	line-height: 25px;
	padding: 0;
	text-transform: none;
	font-weight: normal;
	font-size: 0.7em;
}

#whatsnew .sideBoxContentItem,
#specials .sideBoxContentItem,
#reviews .random-reviews,
#featured .sideBoxContentItem {
	float: left;
	margin-bottom: 0;
	text-align: left;
	width: 100%;
}

.sidebox_content {
	float: left;
	margin: 15px 0;
	width: 100%;
}

.sidebox_price {
	float: left;
	margin: 10px 0 0;
	width: 100%;
}

#featured .sidebox_price {
	margin: 0;
}

.product_sideboxname {
	float: right;
	width: 65%;
}

.product_sideboximage {
	float: left;
	width: 30%;
	margin: 0 10px 0 0;
}

.product_sideboximage>img {
	width: 70px;
	height: auto;
	padding: 5px 0;
}

.sideBoxContentItem {
	border-bottom: 1px dashed #E9E9E9;
	margin-bottom: 10px;
}

.sideBoxContentItem:last-child {
	border: medium none;
}

#specials .sideBoxContent .sideBoxContentItem a,
#whatsnew .sideBoxContentItem a,
#reviews .sideBoxContentItem a {
	float: left;
	width: 100%;
}

/* Category Sidebox */
#right-column #categories li:hover a,
#left-column #categories li:hover a {
	background: none repeat scroll 0 0 #F9F9F9;
	padding: 0 0 0 10px;
}

/* Breadcrumb */
#navBreadCrumb>ul {
	margin: 0;
	padding: 0;
	width: 100%;
}

#navBreadCrumb li {
	background: url("../images/icons/border-sep.png") no-repeat scroll right 11px rgba(0, 0, 0, 0);
	float: left;
	padding: 0.6em 1em 0em 0.5em;
}

#navBreadCrumb li:last-child {
	margin-right: 0;
	background: none;
	border: medium none;
}

#navBreadCrumb li:first-child {
	padding: 0.6em 1em 0em 0;
}

.breadcrumb-current {
	display: inline-block;
	font-size: 1.5em;
	margin: 0em;
	text-align: left;
}

#navBreadCrumb li:last-child a,
#navBreadCrumb li:last-child a:hover,
#navBreadCrumb li:last-child a:active,
#navBreadCrumb li:last-child a:visited,
#navBreadCrumb li:last-child {
	background: none repeat scroll 0 0 transparent;
}

#navBreadCrumb li,
#navBreadCrumb li:hover {
	font-size: 0.9em;
}

.footer-toplinks a:hover {
	font-size: 1em;
}

.breadcrum-text {
	float: left;
	font-size: 1em;
	padding: 1% 0;
}

#breadcrumb-wrapper {
	background: none repeat scroll 0 0 #F1F1F1;
	float: left;
	margin: 2px 0 30px;
	padding: 20px 0;
	width: 100%;
}

/* Category Page */
#indexCategories {
	float: left;
	width: 100%;
}

#subproduct_name img {
	height: auto;
	margin-bottom: 25px;
	width: 100%;
}

#indexCategories #subcategory_names li {
	display: inline-block;
	float: left;
	margin: 5px 10px 5px 0;
	padding: 10px;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#indexCategories #subcategory_names li:first-child {
	background: none repeat scroll 0 0 transparent;
	margin: 5px 0;
	padding-left: 0;
}

#indexCategories #subcategory_names li:first-child:hover {
	background: none repeat scroll 0 0 transparent;
	border-radius: 0;
}

#subproduct_name>a {
	color: #FFFFFF !important;
}

.subcategory_list {
	background: none repeat scroll 0 0 #F9F9F9;
	float: left;
	margin: 0;
	padding: 15px;
	width: 100%;
}

#subcategory_names {
	float: left;
	margin: 0;
	width: 100%;
}

.content_box {
	background: none repeat scroll 0 0 #F9F9F9;
	border: 5px solid #E9E9E9;
	float: left;
	padding: 2%;
	margin: 0 0 15px;
	width: 100%;
}

.content_box strong {
	font-size: 1em;
	font-weight: bold;
	opacity: 0.8;
}

.content_box>p {
	line-height: 20px;
	margin: 5px 0 0;
	opacity: 0.8;
	font-size: 1em;
}

/* Product Listing */
.sort-list {
	float: right;
	margin-top: 6px;
	width: auto;
}

.productlisting_sort {
	float: left;
	width: auto;
}

.sorter label {
	float: left;
	margin-right: 10px;
	margin-top: 5px;
	width: auto;
	font-weight: normal;
}

.sorter {
	width: auto;
	float: left;
}

.sorter form {
	float: left;
	width: auto;
}

#newProductsDefault .buttonRow.forward,
#allProductsDefault .buttonRow.forward,
#featuredDefault .buttonRow.forward,
#indexProductList .buttonRow.forward,
#advSearchResultsDefault .buttonRow.forward {
	float: left;
	width: 35%;
}

#specialsListing .speciallisting_number_links .navSplitPagesLinks {
	float: left;
}

#specialsListing .speciallisting_number_links .navSplitPagesResult,
#indexProductList #productsListingBottomNumber {
	line-height: 42px;
}

.navSplitPagesResult {
	float: right;
	margin-top: 2px;
	text-align: right;
	width: 35%;
}

.next-prev .navSplitPagesResult {
	line-height: 47px;
	margin: 0;
}

.navSplitPagesLinks {
	float: right;
	width: auto;
	line-height: 44px;
}

#indexProductList .tabTable,
#advSearchResultsDefault .tabTable,
#newProductsDefault table,
#featuredDefault table,
#allProductsDefault table {
	float: left;
	width: 100%;
}

.pageresult_bottom,
.pageresult_top,
.speciallisting_number_links {
	float: left;
	margin: 20px 0 0;
	padding: 0 0 20px;
	width: 100%;
}

.pageresult_bottom {
	margin: 20px 0;
}

.special_toplinks {
	margin: -5px 0 0;
	padding: 0 0 18px;
}

.special_bottomlinks {
	margin: 10px 0 0;
}

.productListing-odd,
.productListing-even,
.newproductlisting {
	border: 1px solid #E9E9E9;
	background: none repeat scroll 0 0 #F9F9F9;
	float: left;
	margin: 20px 0 0;
	padding: 10px;
	transition: all 0.3s ease-in-out 0s;
	width: 100%;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.productListing-odd:hover,
.productListing-even:hover,
.newproductlisting:hover {
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.productListing-rowheading {
	display: none;
}

.productListing-data .listingProductImage,
.newproductlisting img {
	margin: 0 20px 0 0;
	padding: 0;
}

.productListing-data .short-description,
.productListing-data .product_name,
.productlisting_btn,
.newproductlisting .product_name,
.newproductlisting .short-description {
	float: left;
	padding: 10px;
	width: 90%;
}

.productListing-data .short-description,
.newproductlisting .short-description {
	font-size: 1em;
}

.productlisting_price {
	float: left;
	font-size: 1.4em;
	line-height: 30px;
	margin: 0 0 15px;
	width: 100%;
}

.productListing-data .product_name>a,
.newproductlisting .product_name>a {
	font-size: 1.4em;
}

.productListing-data .productlisting {
	float: right;
	margin: 0 10px 0 0;
	font-size: 1.2em;
}

.productlisting>input,
.newproductlisting .productnewprice,
.newproductlisting .productnewprice input {
	font-size: 1.2em;
}

.newproductlisting .productlisting {
	float: left;
	width: 100%;
}

#newProductsDefaultListingBottomLinks {
	margin-top: 8px;
}

/* Shopping Cart Page */
#cartImage>img {
	float: left;
	height: auto;
	width: 75px;
}

.cartTableHeading,
#cartSubTotal {
	background-color: #F9F9F9;
	height: 2.5em;
}

table#cartContentsDisplay tr th,
#prevOrders .tableHeading th,
#accountHistInfo .tableHeading th,
#cartSubTotal {
	border-bottom: 1px solid #E9E9E9;
}

table#cartContentsDisplay tr th,
#prevOrders .tableHeading th,
#accountHistInfo .tableHeading {
	background-color: #F9F9F9;
}

#cartContentsDisplay tr,
#prevOrders tr {
	background: none repeat scroll 0 0 transparent;
	border-bottom: 1px solid #E9E9E9;
}

#prevOrders td {
	padding: 15px;
}

.cartProductDisplay,
.cartUnitDisplay,
.cartTotalDisplay,
.cartRemoveItemDisplay,
.cartQuantityUpdate,
.cartQuantity,
#cartInstructionsDisplay,
.cartTotalsDisplay {
	padding: 15px 20px !important;
	vertical-align: middle !important;
	text-align: center;
}

#shoppingCartDefault table,
table#prevOrders,
#accountHistInfo table {
	margin: 0;
}

#cartSubTotal {
	font-size: 1.1em;
	line-height: 2.5em;
	padding-right: 4em;
	text-align: right;
}

table#cartContentsDisplay tr th,
#prevOrders .tableHeading th,
#accountHistInfo .tableHeading,
#accountHistInfo th {
	font-size: 1.1em;
	padding: 10px;
}

.cartQuantityUpdate>input[type="image"]:focus {
	border: medium none;
	box-shadow: none;
}

.cartQuantityUpdate>input[type="image"] {
	border: medium none;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.cartQuantityUpdate>input[type="image"]:hover {
	transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.cartRemoveItemDisplay img {
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	width: 14px;
	height: auto;
}

.cartRemoveItemDisplay img:hover {
	transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.buttonRow.forward.checkout_button {
	float: right;
}

#content-wrapper table#cartContentsDisplay tr td {
	border-bottom: 1px solid #CCCCCC;
	vertical-align: middle;
}

.cartProductDisplay>#product_name {
	display: inline-block;
	line-height: 80px;
}

#content-wrapper table#cartContentsDisplay tr:last-child td {
	border: none;
}

/* Login Page */
#createAccountForm fieldset {
	padding: 0;
	width: 100%;
	margin: 10px 0 0;
}

#loginDefault #createAccountForm .content_box .buttonRow.forward {
	margin: 25px 0 0;
}

#loginDefault .content_box {
	margin: 0;
}

/* CheckOut Shipping */
/*For Steps */
#checkoutShipping .productinfo-rightwrapper {
	float: right;
}

#checkoutShipto {
	float: left;
}

.sendto-address {
	float: left;
	margin: 15px 0;
	width: 100%;
}

.add_title {
	color: #FFFFFF;
	padding: 5px;
}

#checkoutShipping .content_choose FIELDSET {
	border: 1px solid #D9D9D9 !important;
	margin-top: 10px;
	padding: 0.5em 0.5em 0.5em 1em;
}

address {
	float: left;
	font-size: 1em;
	font-weight: bold;
	line-height: 22px;
	width: 100%;
}

#accountHistInfo .change_address {
	display: inline-block;
}

#checkoutShipAddressDefault #checkoutNewAddress select#country,
#checkoutPayAddressDefault #checkoutNewAddress select#country {
	width: 41.5% !important;
}

#checkoutShipping .buttonRow.forward,
#checkoutShipAddressDefault .buttonRow.forward,
#checkoutPayment .buttonRow.forward,
#checkoutPayAddressDefault .buttonRow.forward {
	float: left;
}

#checkoutConfirmDefault #cartContentsDisplay .buttonRow.forward {
	float: none;
	margin: 0;
}

#checkoutShipAddressDefault .productinfo-leftwrapper,
#checkoutShipping .productinfo-leftwrapper,
#checkoutPayAddressDefault .productinfo-leftwrapper,
#addressBookDefault .productinfo-leftwrapper,
#checkoutPayment .productinfo-leftwrapper {
	width: 35%;
}

#checkoutShipAddressDefault .productinfo-rightwrapper,
#checkoutShipping .productinfo-rightwrapper,
#checkoutPayAddressDefault .productinfo-rightwrapper,
#addressBookDefault .productinfo-rightwrapper,
#checkoutPayment .productinfo-rightwrapper {
	width: 65%;
	float: right;
}

#checkoutPayment .product_price.total>strong {
	font-size: 1.2em;
	font-weight: normal;
	margin-right: 10px;
}

#checkoutPayment .product_price.total span.total_price {
	font-size: 1.2em;
	font-weight: normal;
}

#checkoutConfirmDefault .productinfo-leftwrapper {
	margin-right: 2%;
	width: 49%;
}

#checkoutConfirmDefault .productinfo-rightwrapper {
	float: right;
	width: 49%;
}

/* Time Out */
.timeout_email,
.timeout_password {
	float: left;
	width: 100%;
}

/* Account Page */
.allorder_text {
	float: right;
	font-size: 11px;
	margin-top: 5px;
	text-transform: none;
}

#content-wrapper table .user-accountinfo>td,
#content-wrapper #accountHistInfo table td {
	border-bottom: 1px solid #CCCCCC;
	padding: 15px 20px;
}

#accountHistInfo #myAccountShipInfo address,
#accountHistInfo #myAccountPaymentInfo address,
#addressBookDefault address {
	margin-bottom: 20px;
}

/* Write Review Page */
#reviewsWrite .productprice-amount,
#reviewsInfoDefault .productprice-amount {
	width: 100%;
}

#reviewsWrite .product_price,
#reviewsInfoDefault .product_price {
	width: auto;
	line-height: 41px;
}

#reviewsWrite .productprice-amount .normalprice,
#reviewsInfoDefault .productprice-amount .normalprice,
#reviewsDefault .productprice-amount .normalprice {
	color: #595959;
}

.forward.productpage_links {
	float: right;
	width: auto;
}

#reviewsWriteReviewer {
	/* background: url("../images/icons/slider_line.png") repeat-x scroll center bottom transparent;*/
	float: left;
	margin: 10px 0;
	padding: 0 0 15px;
	width: 100%;
}

LABEL#textAreaReviews {
	float: left;
	margin: 0 !important;
}

textarea#review-text {
	margin-bottom: 15px;
}

/* Product Review Info */
.ratings {
	background: none repeat scroll 0 0 #F1F1F1;
	box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1) inset;
	display: block;
	float: left;
	padding: 1.5% 1%;
	width: 98%;
	margin: 20px 0 0;
}

.rating {
	float: right;
}

.user_detail {
	float: left;
	margin-top: 1px;
	text-align: center;
	width: 80%;
}

.bold.user_reviewer {
	float: left;
	font-size: 1em;
	margin-right: 10px;
	margin-top: 2px;
	letter-spacing: 1px;
}

.reviews-list span.date {
	float: left;
	font-size: 1em;
	margin-top: 2px;
	letter-spacing: 1px;
}

.reviews-list .productreviews-wrapper {
	background: none repeat scroll 0 0 #FFFFFF;
	float: left;
	padding: 1%;
	width: 98%;
}

.reviews-list .review_content {
	float: left;
	width: 100%;
}

.review_left {
	float: left;
	width: 100%;
	margin-top: 10px;
	font-size: 1em;
}

#productReviewsDefaultProductPageLink {
	margin-top: 10px;
	float: left;
}

.reviews-list .navSplitPagesResult {
	margin-bottom: 10px;
}

.reviews-list #reviewsDefaultListingTopLinks {
	margin-bottom: 23px;
}

#reviewsListingDefault #reviewsDefaultListingBottomLinks,
.reviews-list #reviewsDefaultListingTopLinks {
	float: left;
}

.productreviews-wrapper .smallProductImage img {
	margin: 0 15px 0 0;
}

/* Search Sidebox */
#searchContent input[type="text"] {
	width: 190px !important;
	font-size: 10px !important;
	float: left;
	height: 26px;
}

#search #searchContent input {
	font-size: 1em;
}

#searchContent a {
	float: left;
}

#currenciesContent select {
	width: 100%;
}

/* Order History Sidebox */
.orderHistList li {
	background: url("../images/icons/cart.gif") no-repeat scroll left center transparent;
}

a.orderhistory_cart {
	padding: 8px 12px 8px 22px !important;
}

/* Manufacturer Info Sidebox */
#manufacturerinfoContent .centeredContent>img {
	border: 1px solid #E9E9E9;
}

#manufacturerinfoContent li {
	text-align: center;
}

/* Contact Us */
FIELDSET#contactUsForm {
	border: medium none !important;
	padding: 0;
}

#contactUsForm .contactus-name {
	margin: 6px 0 0;
	float: left;
	width: 20%;
}

#contactUsForm Label {
	float: left;
	width: 80%;
}

.custom.contactus-details {
	background: none repeat scroll 0 0 #595959;
	float: left;
	width: 100%;
	margin: 0 0 15px;
}

.custom.contactus-details .address,
.custom.contactus-details .mail,
.custom.contactus-details .phone {
	color: #EAEAEA;
	float: left;
	margin: 0;
	padding: 1.5% 1.5% 1.5% 6%;
	width: 24.32%;
	min-height: 5%;
	line-height: 15px;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.custom.contactus-details .address {
	width: 28.87%;
	background-position: 7px -355px !important;
}

.custom.contactus-details .mail {
	background-position: 7px -530px !important;
}

.custom.contactus-details .phone {
	background-position: 7px -443px !important;
}

/* Site Map */
#siteMapList ul {
	list-style: square;
	line-height: 25px;
}

#siteMapList ul li ul {
	margin-left: 20px;
	list-style: circle;
	margin-bottom: 0;
}

ul.nav-child .submenu>a {
	background: url("../images/icons/cat_bullet.png") no-repeat scroll 160px 17px transparent;
}

/* Best Seller Sidebox */
ol {
	counter-reset: li;
	/* Initiate a counter */
	list-style: none;
	/* Remove default numbering */
	*list-style: decimal;
	/* Keep using default numbering for IE6/7 */
	padding: 0;
}

ol ol {
	margin: 0 0 0 2em;
	/* Add some left margin for inner lists */
}

.rectangle-list a {
	position: relative;
	display: block;
	padding: .4em .4em .4em .8em;
	*padding: .4em;
	margin: .5em 0 .5em 2.5em;
	color: #444;
	text-decoration: none;
	transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
}

#bestsellers #bestsellersContent li:last-child {
	margin: 0;
}

#left-column h3 a,
#right-column h3 a,
.buttonRow.back.change_address>a {
	color: #FFFFFF;
}

.rectangle-list a:hover {
	background: none repeat scroll 0 0 #F9F9F9;
}

.rectangle-list a:before {
	content: counter(li);
	counter-increment: li;
	position: absolute;
	left: -2.5em;
	top: 50%;
	margin-top: -1em;
	height: 2em;
	width: 2em;
	line-height: 2em;
	text-align: center;
	color: #FFFFFF;
	transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
}

.rectangle-list a:after {
	position: absolute;
	content: '';
	border: .5em solid transparent;
	left: -1em;
	top: 50%;
	margin-top: -.45em;
	transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
}

#bestsellersContent li a:link,
#bestsellersContent li a:visited {
	padding: 0.4em;
}

.sideBoxContent {
	padding: 10px;
	background: none repeat scroll 0 0 #F9F9F9;
	width: 100%;
	float: left;
	border-bottom: 1px dashed #E9E9E9
}

/* About Us Page */
.simple-boxcontent.team_info {
	border: 1px solid #E9E9E9;
	float: left;
	width: 97.1%;
}

.team_image {
	float: left;
	width: 35%;
}

.team_detail {
	background: none repeat scroll 0 0 #F5F5F5;
	float: right;
	padding: 10px;
	width: 61%;
}

.team_name,
.team_title,
.team_about {
	float: left;
	margin-bottom: 10px;
	width: 100%;
}

.team_about {
	margin-bottom: 0;
}

/* Advanced Search */
#advSearchDefault .content_box>strong {
	float: left;
	margin-bottom: 10px;
	width: 100%;
}

#advSearchDefault .simple-boxcontent {
	border: 1px solid #E9E9E9;
	float: left;
	width: 96.5%;
}

/* Discount Coupon */
#discountcouponInfo>form {
	float: left;
	width: 100%;
}

/* Password Forgotten */
#passwordForgotten .buttonRow.forward {
	margin-top: 10px;
}

#passwordForgotten label {
	float: left;
	margin-top: 9px;
}

/* Page not found */
.pagenot_found {
	float: left;
	width: 100%;
	text-align: center;
}

.notfound_title {
	display: inline-block;
	margin: 30px 0 0;
	text-align: center;
	text-transform: uppercase;
}

.notfound_title>em {
	float: left;
	font-size: 200px;
	line-height: 170px;
}

.notfound_title>p {
	float: left;
	line-height: 76px;
	padding: 0 0 0 30px;
}

.notfound_title p span {
	float: left;
	font-size: 60px;
	text-align: left;
}

.notfound_title p em {
	font-size: 75px;
}

.notfound_text {
	color: #EAEAEA;
	float: left;
	font-size: 40px;
	padding: 2%;
	width: 96%;
}

.home_button .buttonRow.back.button>a {
	color: #EAEAEA;
}

.home_button {
	float: left;
	margin: 20px 0 0;
	text-align: center;
	width: 100%;
}

/* Link Color */
#subproduct_name>a,
#subproduct_name>a:active,
#subproduct_name>a:visited,
a.table_edit_button span.cssButton.small_edit,
#accountDefault a,
.allorder_text>a,
.allorder_text>a:active,
.allorder_text>a:visited,
.buttonRow.product_price>a,
.content_box .bold.user_reviewer>strong,
.content_box .date.user_reviewdate>strong,
#reviewsContent>a,
#searchContent a,
.accordian-header,
.jsn-mainnav.navbar .nav>li>a,
.box_heading_style h1 a,
ul.tabs li a,
.info-links a,
.info-links a:active,
.info-links a:visited,
#siteMapList a,
#siteMapList a:active,
#siteMapList a:visited,
#cartContentsDisplay a.table_edit_button,
#cartContentsDisplay a.table_edit_button:active,
#cartContentsDisplay a.table_edit_button:visited {
	color: #595959;
}

.current-step>a,
.checkout-steps>a {
	color: #595959 !important;
}

.footer-toplinks a {
	color: #E9E9E9 !important;
}

.content_box td:first-child {
	width: 22%;
}

.content_box td:last-child {
	width: 22%;
}

#navBreadCrumb li a:hover,
.footer-toplinks a:hover {
	border-bottom: 1px solid;
}

FIELDSET {
	border: 1px solid #D4D4D4;
}

LEGEND {
	color: #555555;
}

.footer-toplinks>li {
	border-left: 1px solid #E9E9E9;
}

/* Admin Options CSS */
.msadmin_options {
	width: 100%;
}

.logo_setting .accordian-content img {
	background: none repeat scroll 0 0 #AAAAAA;
	padding: 10px;
}

span.admin-text {
	font-size: 11px;
}

.msadmin_options .accordian-content label {
	float: left;
	margin-top: 3px;
	width: 250px;
}

.msadmin_options .slideshow_image {
	float: left;
	width: auto;
}

.msadmin_options .slideshow_image {
	float: left;
	margin: 0 16px 10px 0;
	width: auto;
}

.msadmin_options .slideshow_image:last-child {
	margin-right: 0;
}

.banners .slideshow_image input {
	position: relative;
	top: 23px;
	vertical-align: top;
}

#checkoutConfirmDefault .productinfo-leftwrapper .important {
	float: left;
}

.zoom {
	display: inline-block;
	position: relative;
}

.zoom:after {
	content: '';
	display: block;
	width: 33px;
	height: 33px;
	position: absolute;
	top: 0;
	right: 0;
	background: url(../images/icon.png);
}


.zoom img {
	border: 1px solid #E9E9E9;
	display: block;
	padding: 10px;
}

.zoom img::selection {
	background-color: #FFFFFF;
}

/*#ex2 img:hover { cursor: url(grab.cur), default; }
		#ex2 img:active { cursor: url(grabbed.cur), default; }*/

/* New Design CSS */
h3.product_head_admin {
	text-align: center;
}

.edify_admin .accordian-content {
	margin: 0 0 10px;
	float: left;
}

.edify_admin .accordian-header {
	margin: 0 0 10px;
}

.edify_admin .accordian-header.active {
	margin: 0;
}

.store_banners .banners,
.store_bottom_banners .banners,
.custom_content .custom_block_content {
	border-bottom: 1px solid #E9E9E9;
	margin-bottom: 15px;
}

.custom_content .custom_block_content h5 {
	border-bottom: 1px solid;
	display: inline-block;
	font-size: 16px;
	padding: 0 0 5px;
}

/* Font Awesome */
i.fa {
	margin-right: 10px;
}

p.reviewCount {
	margin-bottom: 0;
}

span.centerBoxHeading {
	padding: 0;
}

#centercontent-wrapper span.centerBoxHeading h2,
.categories-wrapper span.centerBoxHeading h2 {
	padding: 15px 0;
	text-transform: none;
	margin-bottom: 20px;
	background: #FFFFFF;
	font-size: 2em;
	border-bottom: 1px solid #E9E9E9;
}

.centerBoxHeading a {
	float: right;
	font-size: 0.55em;
	margin: 7px 0;
}

/*label*/
span.label-new {
	color: #FFFFFF;
	font-size: 1em;
	padding: 5px 10px;
	position: absolute;
	left: 10px;
	text-transform: uppercase;
	top: 10px;
	background: none repeat scroll 0 0 #7BC5FF;
}

span.label-sale {
	color: #FFFFFF;
	font-size: 1em;
	padding: 5px 10px;
	position: absolute;
	left: 10x;
	text-transform: uppercase;
	top: 45px;
	background: #AE2340;
}

span.label-featured {
	color: #FFFFFF;
	font-size: 1em;
	padding: 5px 10px;
	position: absolute;
	left: 10px;
	text-transform: uppercase;
	top: 45px;
	background: none repeat scroll 0 0 #FFAA00;
}

span.label-upcoming {
	color: #FFFFFF;
	font-size: 1em;
	padding: 5px 10px;
	position: absolute;
	left: 10px;
	text-transform: uppercase;
	top: 105px;
	background: none repeat scroll 0 0 #00C6B7;
}

.bottom-upcoming-wrapper,
.bottom-special-wrapper {
	padding: 30px 0 0 !important;
}

.bottom-upcoming-wrapper #upcomingProducts,
.bottom-special-wrapper #specialsDefault {
	margin: 0;
}

.our-services header,
.know-us header {
	margin-bottom: 30px;
	text-align: center;
	width: 100%;
}

.know-us header,
.extra header,
.my-account header {
	margin-bottom: 0;
	position: relative;
	text-align: center;
	top: -23px;
}

.our-services header>h2,
.know-us header>h2,
.my-account header>h2,
.extra header>h2 {
	background-color: #FFFFFF;
	color: #404040;
	display: inline-block;
	margin: 0;
	padding: 10px;
	text-align: center;
	font-size: 1.4em;
	text-transform: uppercase;
}

.extra header>h2 {
	font-size: 1.5em;
}

.footer_container {
	display: inline-block;
	width: 100%;
	font-size: 0.9em;
	-webkit-backface-visibility: hidden;
}

.know-us-details #indexDefaultMainContent {
	margin: 0;
	padding: 0;
}

.our-services-details .custom-block i {
	font-size: 40px;
}

.our-services-details>div {
	border-left: 1px solid #151b23;
	text-align: center;
}

.our-services-details>div:first-child {
	border: medium none
}

.our-services-details .custom-block {
	color: #ffffff;
	display: inline-block;
	padding: 10px;
	text-align: center;
}

.our-services-details .block-content {
	display: inline-block;
}

.our-services-details .custom-block .block-title {
	font-size: 16px;
	text-transform: uppercase;
	display: block;
	text-align: left;
}

.our-services-details .custom-block .block-text {
	font-size: 14px;
}

.navbar .nav>li.tab_active>a:after,
.navbar .nav>li:hover>a:after,
.navbar .nav>li>a:active:after {
	border-style: solid;
	border-width: 0 7px 6px;
	bottom: 3px;
	content: " ";
	display: block;
	height: 0;
	left: 50%;
	margin: 0 0 0 -7px;
	position: absolute;
	width: 0;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#upcomingProducts .expected-date {
	display: block;
	float: left;
	margin: 1em 0 0;
	text-align: left;
	width: 100%;
}

.product-date {
	display: inline-block;
	text-align: left;
}

.about-us #indexDefaultMainContent>img {
	margin-bottom: 20px;
	-webkit-backface-visibility: hidden;
}

.know-us-details .fa {
	color: rgba(0, 0, 0, 0.3);
	float: left;
	margin-right: 15px;
	position: relative;
	top: -10px;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#indexDefaultMainContent>p.drop-cap:first-letter {
	line-height: 23px;
}

.product_title .navNextPrevWrapper.centeredContent {
	display: inline-block;
	margin: 0 !important;
	position: absolute;
	right: 10px;
	top: 15px;
	width: auto;
}

.navNextPrevList {
	display: inline-block;
	list-style-type: none;
	margin: 0;
	line-height: 0;
	white-space: nowrap;
}

.navNextPrevList>a {
	font-size: 1.2em;
}

.addtocart-info .cart_quantity span.cssButton.button_sold_out,
.addtocart-info .cart_quantity span.cssButtonHover.button_sold_out.button_sold_outHover,
.cssButton.button_sold_out_sm,
.cssButtonHover.button_sold_out_sm.button_sold_out_smHover {
	background: none repeat scroll 0 0 #FF0000;
	display: inline-block;
	font-size: 0.95em;

	position: relative;
	color: #FFFFFF;
	text-align: center;
	/*     line-height: 56px; */
}

/*--Extra Box--*/
.extrabox .arrow-down {
	border-left: 22px solid rgba(0, 0, 0, 0);
	border-right: 22px solid rgba(0, 0, 0, 0);
	border-top: 22px solid #191919;
	bottom: -22px;
	color: #FFFFFF;
	cursor: pointer;
	height: 0;
	position: absolute;
	right: 70px;
	transition: all 1s ease 0s;
	width: 0;
	z-index: 9999;
}

.extrabox .arrow-down i {
	bottom: 6px;
	position: absolute;
	right: -4px;
}

.extrabox .arrow-down.opened {
	border-bottom: 22px solid #FFFFFF;
	border-top: 0 none;
	bottom: 0;
	color: #191919;
	transition: all 0.5s ease 0s;
}

.extrabox .arrow-down.opened i {
	bottom: -20px;
}

.arrow-down i {
	margin: 0;
}

.extrabox {
	background: #2B2B2B;
	position: absolute;
	right: 0;
	width: 100%;
	z-index: 999;
	display: none;
	padding: 30px 0;
}

#contactForm-widget #senderName:focus,
#contactForm-widget #senderEmail:focus,
#contactForm-widget #message:focus {
	border: 1px solid #696969;
	box-shadow: none;
}

#contactForm-widget #senderName,
#contactForm-widget #senderEmail {
	-moz-box-sizing: border-box;
	background: none repeat scroll 0 0 #222222;
	border: 1px solid #2C2C2C;
	border-radius: 3px;
	color: #AAAAAA;
	display: block;
	font-size: 11px;
	height: 40px;
	line-height: 24px;
	padding: 0 10px;
	transition: all 0.3s ease 0s;
	width: 100%;
	float: left;
	margin-bottom: 10px;
}

#contactForm-widget #message {
	-moz-box-sizing: border-box;
	background: none repeat scroll 0 0 #222222;
	border: 1px solid #2C2C2C;
	border-radius: 3px;
	color: #AAAAAA;
	display: block;
	font-size: 11px;
	padding: 16px 10px;
	transition: all 0.3s ease 0s;
	width: 100%;
	float: left;
	margin-bottom: 15px;
}

#contactForm-widget .col-lg-6:first-child {
	padding: 0;
}

#contactForm-widget .col-lg-6.message {
	padding-right: 0;
}

.extra-content header h4 {
	border-bottom: 1px solid rgba(126, 126, 126, 0.3);
	color: #AAAAAA;
	margin: 0 0 20px;
	padding: 0 0 15px;
}

#map1 a {
	display: none;
}

.fieldtrue {
	border: 1px solid #1ABC5B !important;
}

.fielderror {
	border-color: #FF0000 !important;
}

.fieldtrue {
	border: 1px solid #1ABC5B !important;
}

.fielderror {
	border-color: #FF0000 !important;
}

#contactForm-widget span {
	color: #FF0000;
	margin-left: 20px;
}

.widget-content .alert-box.alert-success>p {
	margin: 0;
}

/*Tab CSS */
ul.tabs {
	list-style-type: none;
}

ul.tabs li {
	display: inline;
	margin: 0
}

div.tabcontent {
	display: block
}

div.tabcontents {
	background: none repeat scroll 0 0 #F9F9F9;
	box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.4);
	width: 100%
}

div.tabcontents>div {
	padding: 20px
}

/*IE CSS*/
*+html .grid-32 {
	width: 30%
}

*+html .grid-16 {
	width: 13.666%
}

*+html .grid-72 {
	width: 70%
}

*+html .grid-24 {
	width: 22%
}

*+html .grid-88 {
	width: 89.60%
}

*+html .grid-8 {
	width: 6.30%
}

*+html .nspCol3 {
	width: 32.3%
}

*+html #centercontent-wrapper {
	right: 0 !important;
	position: relative !important
}

*+html #contentarea-wrapper .grid-16 {
	width: 16.666% !important;
	float: left !important
}

*+html #right-column {
	position: relative !important;
	right: 0
}

*+html #productListing {
	float: none
}

*+html #contentarea-wrapper #left-column {
	vertical-align: top !important
}

*+html #centercontent-wrapper {
	position: relative !important;
	left: 17%
}

*+html #left-column {
	position: relative !important;
	left: -68% !important
}

*+html .subcontent-wrapper {
	position: relative !important
}

*+html #breadcrumb-wrapper .breadcrumb-title {
	float: none;
	width: 0
}

*+html #breadcrumb-wrapper {
	padding-bottom: 30px
}

*+html .productListing-data {
	float: left !important;
	width: auto !important
}

*+html #shoppingCartDefault .buttonRow,
*+html #createAcctSuccess .buttonRow,
*+html #addressBookDefault .buttonRow,
*+html #checkoutShipping .buttonRow,
*+html #checkoutPayment .buttonRow,
*+html #checkoutConfirmDefault .buttonRow {
	float: left;
	margin-right: 3px
}

*+html input[type="radio"],
*+html input[type="checkbox"] {
	margin-top: 0;
	margin-bottom: 0
}

*+html #logo-wrapper {
	width: 21.9% !important
}

*+html textarea:focus,
*+html input[type="text"]:focus,
*+html input[type="password"]:focus,
*+html input[type="datetime"]:focus,
*+html input[type="datetime-local"]:focus,
*+html input[type="date"]:focus,
*+html input[type="month"]:focus,
*+html input[type="time"]:focus,
*+html input[type="week"]:focus,
*+html input[type="number"]:focus,
*+html input[type="email"]:focus,
*+html input[type="url"]:focus,
*+html input[type="search"]:focus,
*+html input[type="tel"]:focus,
*+html input[type="color"]:focus,
*+html .uneditable-input:focus {
	outline: thin dotted
}

*+html .dropdown-menu {
	border-right-width: 2px;
	border-bottom-width: 2px
}

*+html .dropdown-toggle {
	margin-bottom: -3px
}

*+html .dropdown-menu .divider {
	width: 100%;
	margin: -5px 0 5px
}

*+html .open {
	z-index: 1000
}

*+html .btn {
	display: inline;
	margin-left: .3em;
	line-height: 20px;
	background-color: #e6e6e6;
	border: 0
}

*+html .btn:hover,
*+html .btn:active,
*+html .btn.active,
*+html .btn.disabled,
*+html .btn[disabled] {
	background-color: #d9d9d9
}

*+html .btn:active,
*+html .btn.active {
	background-color: #ccc
}

*+html .btn:first-child {
	margin-left: 0
}

*+html .btn:hover {
	background-color: #d9d9d9
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden
}

#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%
}

#cboxMiddleLeft,
#cboxBottomLeft {
	clear: left
}

#cboxContent {
	position: relative
}

#cboxLoadedContent {
	overflow: auto;
	-webkit-overflow-scrolling: touch
}

#cboxTitle {
	margin: 0
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
	cursor: pointer
}

.cboxPhoto {
	float: left;
	margin: auto;
	border: 0;
	display: block;
	max-width: none;
	-ms-interpolation-mode: bicubic
}

.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box
}

#cboxOverlay {
	background: #000
}

#colorbox {
	outline: 0
}

#cboxTopLeft {
	width: 14px;
	height: 14px;
	background: url(../images/controls.png) no-repeat 0 0
}

#cboxTopCenter {
	height: 14px;
	background: url(../images/border.png) repeat-x top left
}

#cboxTopRight {
	width: 14px;
	height: 14px;
	background: url(../images/controls.png) no-repeat -36px 0
}

#cboxBottomLeft {
	width: 14px;
	height: 43px;
	background: url(../images/controls.png) no-repeat 0 -32px
}

#cboxBottomCenter {
	height: 43px;
	background: url(../images/border.png) repeat-x bottom left
}

#cboxBottomRight {
	width: 14px;
	height: 43px;
	background: url(../images/controls.png) no-repeat -36px -32px
}

#cboxMiddleLeft {
	width: 14px;
	background: url(../images/controls.png) repeat-y -175px 0
}

#cboxMiddleRight {
	width: 14px;
	background: url(../images/controls.png) repeat-y -211px 0
}

#cboxContent {
	background: #fff;
	overflow: visible
}

.cboxIframe {
	background: #fff
}

#cboxError {
	padding: 50px;
	border: 1px solid #ccc
}

#cboxLoadedContent {
	margin-bottom: 5px
}

#cboxLoadingOverlay {
	background: url(../images/loading_background.png) no-repeat center center
}

#cboxLoadingGraphic {
	background: url(../images/loading.gif) no-repeat center center
}

#cboxTitle {
	position: absolute;
	bottom: -25px;
	left: 0;
	text-align: center;
	width: 100%;
	font-weight: bold;
	color: #7c7c7c
}

#cboxCurrent {
	position: absolute;
	bottom: -25px;
	left: 58px;
	font-weight: bold;
	color: #7c7c7c
}

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	position: absolute;
	bottom: -29px;
	background: url(../images/controls.png) no-repeat 0 0;
	width: 23px !important;
	height: 23px;
	min-height: 0;
	text-indent: -9999px
}

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
	outline: 0
}

#cboxPrevious {
	left: 0;
	background-position: -51px -25px
}

#cboxPrevious:hover {
	background-position: -51px 0
}

#cboxNext {
	left: 27px;
	background-position: -75px -25px
}

#cboxNext:hover {
	background-position: -75px 0
}

#cboxClose {
	right: 0;
	background-position: -100px -25px
}

#cboxClose:hover {
	background-position: -100px 0
}

.cboxSlideshow_on #cboxSlideshow {
	background-position: -125px 0;
	right: 27px
}

.cboxSlideshow_on #cboxSlideshow:hover {
	background-position: -150px 0
}

.cboxSlideshow_off #cboxSlideshow {
	background-position: -150px -25px;
	right: 27px
}

.cboxSlideshow_off #cboxSlideshow:hover {
	background-position: -125px 0
}

/*! Menucool Custom Color Picker v2012.8.30. http://www.menucool.com/color-picker */

div#colorpicker {
	width: 191px;
	height: 164px;
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 0;
	padding-right: 0;
	position: absolute;
	top: 22px;
	left: 0px;
	background-color: #EEE;
	border: 1px solid #BBB;
	display: none;
	z-index: 200;
}

div#colorpicker div {
	float: left;
	padding: 0;
}

div#colorpicker div.clear {
	float: none;
	clear: both;
	border: 0;
	overflow: hidden;
	height: 0;
	font-size: 0;
}

div#colorpicker div#colorContainer {
	float: left;
	border: 0;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	cursor: pointer;
}

div#colorContainer div {
	border: 0;
	border-top: solid 1px black;
	border-left: solid 1px black;
	width: 9px;
	height: 9px;
	overflow: hidden;
	/*for IE6*/
}

div#colorpicker div#hexBox,
div#colorpicker div#bgBox {
	border: 1px solid #999;
	font: normal 9px verdana;
	height: 16px;
	margin-bottom: 4px;
}

div#colorpicker div#hexBox {
	width: 60px;
	padding-left: 3px;
	color: Black;
	line-height: 16px;
}

div#colorpicker div#bgBox {
	width: 110px;
	margin-left: 4px;
	clear: right;
}

div.transChooser {
	background: transparent url("../images/transChooser.gif");
}

span.colorChooser {
	width: 37px;
	height: 20px;
	background: transparent url("../images/colorChooser.gif");
	margin-left: 1px;
	margin-bottom: 4px;
	position: relative;
	display: inline-block;
	overflow: visible;
	vertical-align: middle;
	z-index: 1;
}

/* New CSS 12-04 */
.header-container .header .header-top {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.05);
	line-height: 40px;
	list-style: none outside none;
}

.header-container .header .language-switcher,
.header-container .header .currency_top {
	float: left;
}

.header-container .header .language-switcher a,
.header-container .header .currency_top a {
	text-transform: uppercase;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.1);
	padding: 3px 7px;
	font-size: 85%;
	margin-right: 2px;
}

.header-container .header a {
	font-size: 100%;
	outline: medium none;
}

.header-container .header a,
.greeting {
	color: #FAFAFA;
}

.header-container .header a:hover,
.item .product_image .hover_info a:active {
	color: #FAFAFA;
}

.greeting {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	margin-right: 15px;
	padding-right: 15px;
}

.header-container .header .header-top ul.links {
	float: right;
	margin: 0;
	padding: 0;
}

.header-container .header .header-top .header-top-right ul.links li.first {
	padding-left: 0;
}

.header-container .header .header-top ul.links li {
	float: left;
	margin: 0;
	outline: medium none;
}

.header-container .header .header-top ul.links li a {
	display: block;
	font-size: 100%;
	height: 42px;
	line-height: 38px;
	outline: medium none;
	padding: 0 15px;
	text-decoration: none;
	transition: all 0.25s ease 0s;
}

a {
	outline: medium none;
	text-decoration: none;
	transition: color 450ms ease-in-out 0s, background-color 450ms ease-in-out 0s;
}

ul,
ol {
	list-style: none outside none;
}

.header-container .header .header-top .header-top-right ul.links li.last a {
	background-position: 0 -148px;
	background-color: rgba(0, 0, 0, 0.1);
}

.header .header-det {
	padding: 30px 0;
	position: relative;
}

.header .logo {
	margin: 0;
	outline: medium none;
	text-align: center;
}

.logo img {
	height: auto;
	width: 250px;
}

.form-search {
	position: relative;
	width: 100%;
	margin: 0;
}

.input-text {
	font-size: 1em;
	color: #ddd;
	border-width: 0;
	background: transparent;
}

.form-search input[type="text"] {
	border: medium none;
	box-shadow: none;
	margin: 0;
	min-height: 0;
	outline: medium none;
	padding: 2px 0 3px 1em;
	width: 90%;
}

.header .form-search .input-text {
	background: url("../images/search.png") no-repeat scroll 8px 12px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	float: left;
	line-height: 16px;
	font-size: 0.95em;
	margin: 15px 0px;
	padding: 6px 12px;
	border: 1px solid rgba(0, 0, 0, 0.05);
	cursor: text;
	padding: 11px 14px 11px 34px;
	width: 85%;
	color: #FAFAFA !important;
	transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
}

/*Top Header*/
.header-container .header .header-content .logo>a:hover {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.header .form-search .button {
	display: none;
}

.block-header label {
	color: #FAFAFA;
	font-weight: normal;
	margin: 0;
}

.header .block-header .language-switcher {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	margin-right: 14px;
	padding-right: 14px;
}

.header .block-header .language-switcher,
.header .block-header .currency_top {
	display: inline-block;
}

.greeting_msg {
	display: inline-block;
	margin: 26px 0;
	width: 100%;
	text-align: right;
}

.greeting_msg>a {
	padding: 3px 7px;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.1);
}

/*Nav*/
.header-container .header #nav>li>a {
	font-size: 1.05em;
	text-transform: uppercase;
}

.header-container .header #nav li ul a {
	font-size: 100%;
	text-transform: none;
	border-bottom: 1px solid #F2F2F2;
	background-color: #FFFFFF;
}

.header-container #nav li ul li a.parent {
	background: url("../images/icons/cat_bullet-1.png") no-repeat scroll right 15px center #FFFFFF;
}

.nav-maincontainer {
	padding: 0;
}

.header-container #nav>li.tab_active>a,
.header-container #nav>li:hover>a,
.header-container #nav>li>a:active,
.header-container #nav li a {
	color: #FAFAFA;
}

.header-container #nav li ul a:hover {
	background-color: #FFFFFF;
}

/* Slideshow */
.slideshow-container,
.main-breadcrumb {
	background: url("../images/bgpatterns/overlay1.png") repeat scroll 0 0 #F5F5F5;
	box-shadow: 0 5px 15px -15px inset;
	padding: 10px 0;
}

.main-breadcrumb {
	border-bottom: 1px dashed #E9E9E9;
	padding: 10px 0;
}

.bx-viewport>ul {
	padding: 0;
}

.top-custom-block {
	background: none repeat scroll 0 0 #F9F9F9;
	border: 1px dashed #E9E9E9;
	margin: 0 0 20px;
}

/* DIRECTION CONTROLS (NEXT / PREV) */
.brands-wrapper .bx-wrapper .bx-prev,
#additionalimages-slider .owl-controls .owl-prev {
	left: 10px;
	background: url("../images/brands_slider_arrows.png") no-repeat scroll 100% 0% #CCCCCC;
}

.brands-wrapper .bx-wrapper .bx-next,
#additionalimages-slider .owl-controls .owl-next {
	right: 10px;
	background: url("../images/brands_slider_arrows.png") no-repeat scroll 0% 100% #CCCCCC;
}

.brands-wrapper .bx-wrapper .bx-controls-direction a,
#additionalimages-slider .owl-controls .owl-prev,
#additionalimages-slider .owl-controls .owl-next {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	outline: 0;
	width: 22px;
	height: 22px;
	text-indent: -9999px;
	z-index: 99;
}

#additionalimages-slider .owl-controls .owl-prev,
#additionalimages-slider .owl-controls .owl-next {
	margin-top: -10px;
}

.brands-wrapper,
.category-slideshow-wrapper {
	padding: 30px 0;
	background: none repeat scroll 0 0 #F3F3F3;
}

.category-slideshow-wrapper {
	background: none repeat scroll 0 0 #FFFFFF;
}

.brands-wrapper h2,
.category-slideshow-wrapper h2,
.box_heading h2 {
	margin: 0;
	display: inline-block;
	margin: 0;
	padding: 10px;
	font-size: 1.4em;
	text-transform: uppercase;
	position: relative;
	background: #FFFFFF;
}

.brands-wrapper h2 {
	background: none repeat scroll 0 0 #f3f3f3;
}

#indexCategories .box_heading h2,
#indexProductList .box_heading h2 {
	font-size: 1.1em;
}

.brands-wrapper header,
.category-slideshow-wrapper header,
.box_heading header {
	text-align: left;
	margin: 0 0 30px;
	position: relative;
}

.brands-wrapper header:before,
.category-slideshow-wrapper header:before,
.box_heading header:before {
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	top: 50%;
	width: 100%;
}

.footer-top-wrapper {
	padding: 30px 0 20px;
}

.newsletter h2,
.social h2,
.payment h2 {
	color: #FAFAFA;
}

/*News Letter*/
.newsletter #mc_embed_signup label {
	font-size: 1.5em;
	font-weight: normal;
	line-height: 75px;
	padding-bottom: 30px;
	display: inline-block;
}

#mc-embedded-subscribe-form>p {
	padding-bottom: 15px;
}

.footer-1,
#footer-2 {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #151b23;
}

#footer-3 {
	padding-bottom: 25px
}

.footer-wrapper #footer-2 h2,
.footer-wrapper #footer-3 h2 {
	color: #fff;
	font-size: 16px;
	margin: 0 0 20px;
	padding-bottom: 10px;
	position: relative;
	text-transform: uppercase;
}

.footer-wrapper #footer-2 h2 {
	margin: 0 0 10px
}

.footer-wrapper #footer-2 h2:after,
.footer-wrapper #footer-3 h2:after {
	background: none repeat scroll 0 0 #fff;
	bottom: 0;
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	width: 50px;
}

.footer-wrapper .newsletter h2 {
	border-bottom: medium none;
	padding: 0 0 30px;
	font-size: 18px;
	text-transform: uppercase;
	margin: 0;
}

.newsletter #mc_embed_signup input.email {
	background: none repeat scroll 0 0 #FFFFFF;
	color: #404040;
	display: block;
	float: left;
	margin: 0;
	min-width: 130px;
	min-height: 39px;
	padding: 15px 0;
	text-indent: 15px;
	width: 70%;
}

.newsletter #mc_embed_signup input.button,
#contactForm-widget input[type="submit"] {
	display: block;
	float: left;
	margin: 0;
	min-width: 90px;
	min-height: 39px;
	width: 100% !important;
	border-bottom: 3px solid rgba(0, 0, 0, 0.2);
	line-height: inherit;
	height: auto;
	padding: 9px 15px;
	font-weight: normal;
	border-radius: 0;
	background-color: rgba(0, 0, 0, 0.3);
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.newsletter #mc_embed_signup input.button {
	border-bottom: medium none;
	padding: 15px 30px;
}

#contactForm-widget input[type="submit"] {
	width: auto !important;
	border-top: none;
	border-left: none;
	border-right: none;
	color: #FAFAFA;
}

.newsletter .fa {
	color: rgba(0, 0, 0, 0.3);
	float: left;
	left: 10px;
	margin-right: 30px;
	position: relative;
	transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	top: 5px;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
	-webkit-backface-visibility: hidden;
}

.footer-wrapper .newsletter {
	text-align: center;
}

.newsletter #mc_embed_signup .clear {
	clear: none;
	display: inline-block;
}

.newsletter #mc_embed_signup input {
	border: none;
}

.newsletter #mc_embed_signup form {
	padding: 0 0 0 14.5% !important;
}

.newsletter>header,
.social>header,
.payment>header {
	margin: 0 0 20px;
	width: 100%;
	text-align: left;
	float: left;
}

/*Social Wrapper*/
.social-wrapper {
	display: inline-block;
	text-align: left;
	width: 100%;
}

.social_bookmarks {
	display: inline-block;
	margin: 0;
	padding: 0;
	text-align: left;
}

.social_bookmarks li {
	padding: 0 5px 5px 0;
	float: left;
}

.social_bookmarks li a {
	border: 1px solid rgba(0, 0, 0, 0.2);
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.1);
	color: #FFFFFF;
	display: block;
	float: left;
	height: 40px;
	line-height: 40px;
	text-align: center;
	transition: all 0.3s ease-in-out 0s !important;
	-moz-transition: all 0.3s ease-in-out 0s !important;
	-webkit-transition: all 0.3s ease-in-out 0s !important;
	-o-transition: all 0.3s ease-in-out 0s !important;
	-ms-transition: all 0.3s ease-in-out 0s !important;
	width: 40px;
}

ul.social_bookmarks .fa {
	margin: 0;
}

.social_bookmarks li.facebook a {
	border: 1px solid #2A5BB6;
	background: none repeat scroll 0 0 #2A5BB6;
}

.social_bookmarks li.twitter a {
	border: 1px solid #12CBED;
	background: none repeat scroll 0 0 #12CBED;
}

.social_bookmarks li.pinterest a {
	border: 1px solid #C11B1B;
	background: none repeat scroll 0 0 #C11B1B;
}

.social_bookmarks li.google_plus a {
	border: 1px solid #DD4B39;
	background: none repeat scroll 0 0 #DD4B39;
}

.social_bookmarks li.tumblr a {
	border: 1px solid #3F729B;
	background: none repeat scroll 0 0 #3F729B;
}

.social_bookmarks li.linkedin a {
	border: 1px solid #007FB1;
	background: none repeat scroll 0 0 #007FB1;
}

.social_bookmarks li.youtube a {
	border: 1px solid #D63030;
	background: none repeat scroll 0 0 #D63030;
}

.social_bookmarks li a:hover {
	border: 1px solid rgba(0, 0, 0, 0.1);
	color: #FFFFFF;
	transition: all 0.3s ease-in-out 0s !important;
	-moz-transition: all 0.3s ease-in-out 0s !important;
	-webkit-transition: all 0.3s ease-in-out 0s !important;
	-o-transition: all 0.3s ease-in-out 0s !important;
	-ms-transition: all 0.3s ease-in-out 0s !important;
}

/*On Hover*/
.slide {
	overflow: hidden;
	position: relative;
}

.slide .blind,
.item .blind,
.centerBoxContentsAlsoPurch .blind,
.specialsListBoxContents .blind {
	left: 0;
	top: 0;
}

.slide .blind,
.item .product_image .blind,
.centerBoxContentsAlsoPurch .product_image .blind,
.specialsListBoxContents .product_image .blind {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
	height: 100%;
	position: absolute;
	transform: scale(0, 1);
	-moz-transform: scale(0, 1);
	-webkit-transform: scale(0, 1);
	-o-transform: scale(0, 1);
	-ms-transform: scale(0, 1);
	transition: all 200ms linear 0s;
	-moz-transition: all 200ms linear 0s;
	-webkit-transition: all 200ms linear 0s;
	-o-transition: all 200ms linear 0s;
	-ms-transition: all 200ms linear 0s;
	width: 100%;
}

.item .product_image .blind,
.centerBoxContentsAlsoPurch .product_image .blind,
.specialsListBoxContents .product_image .blind {
	height: 76%;
}

.hover_info {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.item .product_image .hover_info,
.centerBoxContentsAlsoPurch .product_image .hover_info,
.specialsListBoxContents .product_image .hover_info {
	height: 76%;
}

.slide:hover .blind,
.item .product_image:hover .blind,
.centerBoxContentsAlsoPurch .product_image:hover .blind,
.specialsListBoxContents .product_image:hover .blind {
	transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
}

.hover_info:hover h1 {
	right: 0;
	transition-delay: 250ms;
	-moz-transition-delay: 250ms;
	-webkit-transition-delay: 250ms;
	-o-transition-delay: 250ms;
	-ms-transition-delay: 250ms;
}

.hover_info h1 {
	color: #fafafa;
	font-size: 1.5em;
	right: -100%;
	padding: 10px 20px;
	position: absolute;
	top: 0;
	margin: 0;
	transition: right 200ms linear 0s;
	-moz-transition: right 200ms linear 0s;
	-webkit-transition: right 200ms linear 0s;
	-o-transition: right 200ms linear 0s;
	-ms-transition: right 200ms linear 0s;
}

.hover_info:hover p {
	opacity: 1;
	transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-webkit-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
}

.hover_info p {
	color: #FFFFFF;
	font-size: 1.1em;
	left: 5%;
	line-height: 1.4;
	opacity: 0;
	position: absolute;
	text-align: center;
	top: 80px;
	transition: opacity 200ms linear 0s;
	-moz-transition: opacity 200ms linear 0s;
	-webkit-transition: opacity 200ms linear 0s;
	-o-transition: opacity 200ms linear 0s;
	-ms-transition: opacity 200ms linear 0s;
	width: 90%;
}

.item .product_image .hover_info p,
.centerBoxContentsAlsoPurch .product_image .hover_info p,
.specialsListBoxContents .product_image .hover_info p {
	top: 40px;
	font-size: 1em;
}

.hover_info:hover a {
	left: 0;
	transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-moz-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-webkit-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-o-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-ms-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
}

.hover_info a:hover,
.hover_info a:visited,
.hover_info a:active {
	color: #FAFAFA;
}

.hover_info a {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
	bottom: 0;
	color: #FAFAFA;
	font-size: 1em;
	padding: 10px 20px;
	position: absolute;
	left: -100%;
}

.item .product_image .hover_info .zoom-button a,
.item .product_image .hover_info .detailbutton-wrapper a,
.centerBoxContentsAlsoPurch .product_image .hover_info .zoom-button a,
.centerBoxContentsAlsoPurch .product_image .hover_info .detailbutton-wrapper a,
.specialsListBoxContents .product_image .hover_info .zoom-button a,
.specialsListBoxContents .product_image .hover_info .detailbutton-wrapper a {
	background: none repeat scroll 0 0 #FFFFFF;
	border-radius: 50%;
	bottom: 60px;
	font-size: 1em;
	height: 50px;
	left: 0;
	line-height: 50px;
	opacity: 0;
	padding: 0 17px;
	position: absolute;
}

.item .product_image .hover_info:hover .zoom-button a,
.item .product_image .hover_info:hover .detailbutton-wrapper a,
.centerBoxContentsAlsoPurch .product_image .hover_info:hover .detailbutton-wrapper a,
.centerBoxContentsAlsoPurch .product_image .hover_info:hover .zoom-button a,
.specialsListBoxContents .product_image .hover_info:hover .detailbutton-wrapper a,
.specialsListBoxContents .product_image .hover_info:hover .zoom-button a {
	opacity: 1;
	left: 29.5%;
	transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-moz-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-webkit-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-o-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
	-ms-transition: left 200ms linear 700ms, color 0s ease 0s, background 200ms linear 0s;
}

.item .product_image .hover_info:hover .detailbutton-wrapper a,
.centerBoxContentsAlsoPurch .product_image .hover_info:hover .detailbutton-wrapper a,
.specialsListBoxContents .product_image .hover_info:hover .detailbutton-wrapper a {
	left: 51%;
}

.item .product_image .hover_info a:hover,
.centerBoxContentsAlsoPurch .product_image .hover_info a:hover,
.specialsListBoxContents .product_image .hover_info a:hover {
	color: #FFFFFF;
}

/*Main Content*/
.main-top {
	padding: 30px 0;
}

.owl-carousel {
	overflow: hidden;
}

#indexCategories .box_heading,
#indexProductList .box_heading {
	display: block;
}

.owl-pagination {
	display: none;
}

.brands .item>img {
	height: auto;
	width: 100%;
}

.brands .item {
	margin: 5px 15px;
}

#main-slideshow .owl-buttons {
	opacity: 0.5;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#main-slideshow:hover .owl-buttons {
	opacity: 1;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.centerBoxWrapper .owl-theme .owl-controls .owl-buttons div,
#main-slideshow .owl-controls .owl-buttons div,
.brands-slider .owl-controls .owl-buttons div {
	background: url("../images/icons/item-slider.png") no-repeat scroll 100% 100% #CCCCCC;
	cursor: pointer;
	height: 60px;
	position: absolute;
	top: 42%;
	text-indent: -9999px;
	overflow: hidden;
	width: 50px;
	outline: 0;
	z-index: 99;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.brands-slider .owl-controls .owl-buttons div {
	background: url("../images/icons/item-slider.png") no-repeat scroll 94% 93% #CCCCCC;
	height: 50px;
	width: 40px;
	top: 29%;
}

.centerBoxWrapper .owl-theme .owl-controls .owl-buttons div.owl-next,
.brands-slider .owl-controls .owl-buttons div.owl-next,
#main-slideshow .owl-controls .owl-buttons div.owl-next {
	right: 0%;
	background-position: 100% 0;
}

.brands-slider .owl-controls .owl-buttons div.owl-next {
	background-position: 90% 8%;
}

.incenteer {
	display: inline-block;
	position: absolute;
	top: 50%;
	z-index: 5;
	left:0px;
}

.centerBoxWrapper .owl-theme .owl-controls .owl-buttons .disabled,
.centerBoxWrapper .owl-theme .owl-controls .owl-buttons .disabled:hover,
#additionalimages-slider .owl-controls .owl-prev.disabled,
#additionalimages-slider .owl-controls .owl-next.disabled,
#additionalimages-slider .owl-controls .owl-prev.disabled:hover,
#additionalimages-slider .owl-controls .owl-next.disabled:hover,
.brands-slider .owl-controls .owl-next.disabled,
.brands-slider .owl-controls .owl-next.disabled:hover,
.brands-slider .owl-controls .owl-prev.disabled,
.slideimg,
.brands-slider .owl-controls .owl-prev.disabled:hover {
	cursor: default;
	background-color: #E9E9E9;
}

.custom-banner-container {
	background: none repeat scroll 0 0 #F9F9F9;
	border-bottom: 1px dashed #E9E9E9;
	border-top: 1px dashed #E9E9E9;
	padding: 0;
}

.main-top .main .custom-banner-container {
	background: none;
	border: none;
}

.custom-banner-container .custom-banner-image img {
	width: 100%;
	height: auto;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.custom-banner-container .custom-banner-image a {
	display: block;
	overflow: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.top-banner h3 {
	font-weight: 100;
	margin: 20px 0 12px;
	text-align: center;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.top-banner p {
	margin-bottom: 20px;
	text-align: center;
}

/*Custom-content*/
.custom-content-wrapper {
	padding: 30px 0;
	background: #2B2B2B;
}

.bottom-banner-wrapper {
	padding: 30px 0;
	background: none repeat scroll 0 0 #F9F9F9;
	border-bottom: 1px dashed #E9E9E9;
}

.bottom-banner img {
	background: none repeat scroll 0 0 #F9F9F9;
}

/*Footer*/
.footer-wrapper {
	background: #2B2B2B;
	color: #AAAAAA;
	font-size: 100%;
}

.aboutus_address,
.aboutus_mail,
.aboutus_phone,
.aboutus_fax,
.aboutus_skype {
	float: left;
	padding-bottom: 10px;
	text-align: left;
	width: 100%;
}

.footer-wrapper .contact-us li:last-child {
	padding: 0;
}

.mail>a {
	color: #AAAAAA;
}

.footer-wrapper {
	padding: 30px 0 0;
}

.footer-wrapper header {
	margin: 0 0 25px;
	width: 100%;
}

.footer-wrapper ul {
	padding: 0;
}

.extra-links>li {
	line-height: 30px;
	width: 100%;
}

.extra-links li a {
	color: #AAAAAA;
	display: inline-block;
	padding: 5px 0;
	width: 100%;
}

.extra-links li a:last-child {
	padding: 5px 0 0
}

.extra-links li a:hover {
	color: #FFFFFF;
}

#footer-2 .about-us-container,
#footer-2 .contact-us ul {
	padding-top: 10px;
}

.contact-us .fa,
.about-us .address .fa {
	border: 1px solid #AAAAAA;
	border-radius: 0%;
	color: #AAAAAA;
	display: inline-block;
	height: 35px;
	line-height: 35px;
	text-align: center;
	width: 35px;
	float: left;
	margin-right: 15px;
}

.contact-us ul li p {
	display: inline-block;
	width: 75%;
}

.contact-us ul li:last-child>p,
#footer-2 .about-us-container .address {
	margin-bottom: 0;
}

.contact-us ul li:hover .fa,
.about-us .address:hover .fa {
	border: 1px solid rgba(255, 255, 255, 0.2);
	color: #FFFFFF;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

.about-us-container div.address {
	float: left;
	margin: 10px 0 0;
	width: 100%;
}

.extra-links li a .fa {
	margin-right: -16px;
	line-height: inherit;
	opacity: 0;
	transition: all 0.3s ease 0s;
}

.extra-links li a:hover .fa {
	margin-right: 2px;
	opacity: 1;
	transition: all 0.3s ease 0s;
}

.copyright {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
	padding: 15px 0;
}

.footer-v3-container .footer-wrapper .copyright {
	padding: 15px 30px
}

.copyright-text>p {
	margin: 0;
	text-align: center;
}

.copyright .top {
	background: url("../images/totop_arrows.png") no-repeat scroll 0% 0% #E1E1E1;
	cursor: pointer;
	float: right;
	height: 45px;
	right: 20px;
	bottom: 20px;
	position: fixed;
	color: #FFFFFF;
	width: 45px;
	padding: 0;
	font-size: 0;
	z-index: 999;
}

/*Sticky Header*/
.sticky-header-wrapper {
	float: left;
	width: 100%;
	box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.1);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999;
	display: none;
}

.sticky-header-wrapper .logo {
	margin: 12px 0;
	text-align: left;
}

.sticky-header-content .logo img {
	width: 180px;
}

.sticky-header-wrapper .custom_links.topbar_links {
	float: right;
	line-height: 30px;
	margin: 0;
}

.sticky-header-wrapper .top-search {
	float: left;
}

.sticky-header-wrapper .topbar_links a {
	float: right;
	color: #FFFFFF;
	margin: 15px 1px;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.1);
}

.topbar_links a {
	float: left;
	padding: 10px;
	line-height: 22px;
}

.sticky-header-wrapper .topbar_links nav ul li {
	float: right;
	line-height: 30px;
}

.sticky-header-wrapper .topbar_links nav ul li:hover {
	background: none;
}

.sticky-header-wrapper .custom_links.topbar_links ul {
	margin: 0;
}

.header .sticky-header-content .form-search .input-text {
	width: 100%;
}

/*Product Info*/
.cart_quantity>input[type="text"] {
	float: left;
	font-size: 1.2em;
	margin: 0 0.8em 0 0;
	min-height: 44px;
	padding: 10px;
	text-align: center;
	width: 60px;
}

ul.tabs {
	margin-bottom: 30px;
	padding: 0;
	position: relative;
	text-align: left;
}

ul.tabs:before {
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	top: 50%;
	width: 100%;
}

ul.tabs li a {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 3px solid #E9E9E9;
	cursor: pointer;
	display: inline-block;
	font-size: 1.1em;
	list-style: none outside none;
	margin: 0 10px 0 0;
	padding: 5px 15px;
	position: relative;
	text-transform: uppercase;
}

.product_info #productDetailsList,
.product_info #productDetailsList li {
	margin: 0;
}

#centercontent-wrapper header {
	margin: 0 0 30px;
	position: relative;
	text-align: left;
}

#centercontent-wrapper header:before {
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	top: 50%;
	width: 100%;
}

#centercontent-wrapper header>h1,
.checkout-steps {
	background: none repeat scroll 0 0 #FFFFFF;
	display: inline-block;
	margin: 0;
	padding: 10px;
	position: relative;
	font-size: 1.4em;
	text-transform: uppercase;
	line-height: 22px;
}

.checkout-steps,
.current-step {
	border: 3px solid #E9E9E9;
	margin: 0 10px 0 0 !important;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-ms-transition: all 0.3s ease-in-out 0s;
}

#indexProductListCatDescription,
#categoryDescription {
	margin-bottom: 30px;
}

#categoryImgListing>img {
	height: auto;
	width: 100%;
}

/*ToolTip CSS*/
/*.tooltip{
    position:absolute;
    z-index:1020;
    display:block;
    visibility:visible;
    padding:5px;
    font-size:11px;
    opacity:0;
    filter:alpha(opacity=0)
}
.tooltip.in{
    opacity:.8;
    filter:alpha(opacity=80)
}
.tooltip.top{
    margin-top:-2px
}
.tooltip.right{
    margin-left:2px
}
.tooltip.bottom{
    margin-top:2px
}
.tooltip.left{
    margin-left:-2px
}
.tooltip.top .tooltip-arrow{
    bottom:0;
    left:50%;
    margin-left:-5px;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-top:5px solid #4EB1BA
}
.tooltip.left .tooltip-arrow{
    top:50%;
    right:0;
    margin-top:-5px;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-left:5px solid #000
}
.tooltip.bottom .tooltip-arrow{
    top:0;
    left:50%;
    margin-left:-5px;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-bottom:5px solid #000
}
.tooltip.right .tooltip-arrow{
    top:50%;
    left:0;
    margin-top:-5px;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-right:5px solid #000
}
.tooltip-inner{
    max-width:200px;
    padding:3px 8px;
    color:#fff;
    text-align:center;
    text-decoration:none;
    background-color:#000;
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    border-radius:4px
}
.tooltip-arrow{
    position:absolute;
    width:0;
    height:0
}*/
/*Alert Box*/
.alert {
	font-weight: normal;
	border: none;
	display: inline-block;
}

button.close:hover {
	background: none;
}

.mousetrap {
	width: 100% !important;
	height: 100% !important;
}

.cloud-zoom img {
	width: 100%;
}

/* This is the moving lens square underneath the mouse pointer. */
.cloud-zoom-lens {
	border: 1px solid #ccc;
	margin: -1px;
	/* Set this to minus the border thickness. */
	background-color: #fff;
	cursor: move;
}

/* This is for the title text. */
.cloud-zoom-title {
	position: absolute !important;
	background-color: #000;
	color: #FFFFFF;
	padding: 3px;
	width: 100%;
	text-align: center;
	font-weight: bold;
	font-size: 10px;
	top: 0px;
	display: none;
}

/* This is the zoom window. */
.cloud-zoom-big {
	border: 4px solid #ccc;
	overflow: hidden;
	background: #FFFFFF
}

/* This is the loading message. */
.cloud-zoom-loading {
	color: white;
	background: #222;
	padding: 3px;
	border: 1px solid #000;
	display: none;
}

/*Login Page*/
.split-login .content {
	border-bottom: 0 none;
	border-style: solid solid none;
	border-width: 1px 1px 0;
	min-height: 300px;
	padding: 14px 21px;
	border-color: #E9E9E9;
	background: none repeat scroll 0 0 #F9F9F9;
}

.split-login .buttons-set {
	border-style: solid;
	border-width: 1px;
	margin: 0;
	padding: 8px 13px;
	display: inline-block;
	width: 100%;
}

.split-login .buttons-set {
	background: none repeat scroll 0 0 #F9F9F9;
	border-color: #E9E9E9;
}

.split-set {
	border-top-style: solid;
	border-top-width: 1px;
	clear: both;
	margin: 4em 0 0;
	padding: 8px 0 0;
	text-align: right;
}

.split-set {
	border-top-color: #E9E9E9;
}

.split-login .content h4,
.create-account-page .content h4,
#contactUsDefault .content h4,
#checkoutShipping .content h4,
#checkoutPayment .content h4,
#checkoutConfirmDefault .content h4,
#checkoutSuccess .content h4,
#accountDefault .content h4,
#accountHistoryDefault .content h4,
#accountHistInfo .content h4,
#accountEditDefault .content h4,
#addressBookDefault .content h4,
#addressBookProcessDefault .content h4,
#acctNewslettersDefault .content h4,
#accountNotifications .content h4,
#checkoutShipAddressDefault .content h4,
#checkoutPayAddressDefault .content h4,
#timeoutDefault .content h4,
#createAcctSuccess .content h4,
#gvFaqDefault .content h4,
#reviewsDefault .content h4,
#advSearchDefault .content h4 {
	border-bottom: 1px solid #E9E9E9;
	margin: 0 0 20px;
	padding: 0 0 10px;
	text-align: left;
}

#advSearchDefault .content h4 .forward {
	display: inline-block;
	float: right;
	font-size: 0.8em;
}

#advSearchDefault .content .row .select2-container {
	margin-right: 10px;
}

.split-login .create-account-button {
	display: inline-block;
	float: right;
}

.login-buttons .button_login {
	float: right;
}

.login-buttons>a {
	float: left;
	line-height: 44px;
}

.split-login #loginForm label {
	float: left;
	font-weight: 400;
	position: relative;
	z-index: 0;
}

.split-login #loginForm input#login-email-address,
.split-login #loginForm input#login-password,
#accountEditDefault .content input[type="text"],
#addressBookProcessDefault .content input[type="text"],
#accountPassword .content input[type="text"],
#accountPassword .content input[type="password"],
#checkoutNewAddress .content input[type="text"],
#gvFaqDefault .content input[type="text"],
#discountcouponInfo .content input[type="text"],
#advSearchDefault .content input[type="text"] {
	width: 100%;
	min-height: 35px;
}

/*Create Account*/
.create-account-page .content,
#passwordForgotten .content,
#timeoutDefault .content,
#contactUsDefault .content,
#checkoutShipping .content,
#checkoutPayment .content,
#checkoutConfirmDefault .content,
#checkoutSuccess .content,
#accountDefault .content,
#accountHistoryDefault .content,
#accountHistInfo .content,
#accountEditDefault .content,
#addressBookDefault .content,
#addressBookProcessDefault .content,
#accountPassword .content,
#acctNewslettersDefault .content,
#accountNotifications .content,
#checkoutShipAddressDefault .content,
#checkoutPayAddressDefault .content,
#createAcctSuccess .content,
#gvFaqDefault .content,
#discountcouponInfo .content,
#reviewsInfoDefault .content,
#reviewsDefault .content,
#reviewsWrite .content,
#advSearchDefault .content {
	padding: 14px 21px;
	border: 1px solid #E9E9E9;
	background: none repeat scroll 0 0 #F9F9F9;
	margin: 20px 0;
}

#passwordForgotten .content,
#timeoutDefault .content {
	margin: 0;
}

#contactUsDefault .content,
#reviewsDefault .content,
#reviewsWrite .content {
	margin: 0 0 20px
}

.create-account-page .content label,
#timeoutDefault .content label,
#contactUsDefault .content label,
#checkoutSuccess .content label,
#accountEditDefault .content label,
#addressBookProcessDefault .content label,
#accountPassword .content label,
#checkoutNewAddress .content label,
#gvFaqDefault .content label,
#discountcouponInfo .content label,
#advSearchDefault .content label,
#acctNewslettersDefault .content label {
	font-weight: 400;
}

.create-account-page .content input.inputlogin,
.create-account-page .content select.inputlogin,
#passwordForgotten .content input#email-address,
#timeoutDefault .content #login-email-address,
#timeoutDefault .content #login-password,
#contactUsDefault .content input#contactname,
#contactUsDefault .content input#email-address,
#contactUsDefault .content #enquiry {
	width: 100%;
	min-height: 35px;
}

.create-account-page .content .select2-container.inputlogin,
.create-account-page .content select.inputlogin,
#addressBookProcessDefault .content .select2-container,
#checkoutNewAddress .content .select2-container {
	width: 100% !important;
}

.create-account-page .content .select2-container .select2-choice,
#addressBookProcessDefault .content .select2-container .select2-choice,
#checkoutNewAddress .content .select2-container .select2-choice,
#advSearchDefault .content .select2-container .select2-choice {
	height: 35px;
	line-height: 35px;
}

.create-account-page .content .select2-container .select2-choice .select2-arrow,
#addressBookProcessDefault .content .select2-container .select2-choice .select2-arrow,
#checkoutNewAddress .content .select2-container .select2-choice .select2-arrow,
#advSearchDefault .content .select2-container .select2-choice .select2-arrow {
	top: 4px;
}

.create-account-page .submit-info .button_submit {
	float: right;
}

.create-account-page .newsletter-details .content {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
	border-color: #E9E9E9 -moz-use-text-color -moz-use-text-color;
	border-style: solid none none;
	border-width: 1px medium medium;
	padding: 20px 0 0;
}

#checkoutShipAddressDefault .row.address-entries address,
#checkoutPayAddressDefault .row.address-entries address {
	margin: 10px 0 0 20px;
}

/*Timeout*/
#timeoutDefault .timeoutbuttons {
	display: inline-block;
	width: 100%;
}

#timeoutDefault .timeoutbuttons .button_login {
	float: right;
}

#timeoutDefault .timeoutbuttons a {
	float: left;
	margin: 12px 0;
}

/*Contact Us*/
.contactus-sendbutton .button_send {
	float: right;
}

.contactus-sendbutton .alert-text {
	display: inline-block;
	float: left;
}

.contactus-sendbutton {
	margin-top: 20px;
}

/*Checkout*/
.shipping-methods>.col-lg-12 {
	border-bottom: 1px solid #E9E9E9;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.ship-method {
	margin-top: 15px;
}

.shipping-methods:last-child>.col-lg-12 {
	border: medium none;
	margin-bottom: 0;
	padding-bottom: 0;
}

#checkoutShipping .checkout-shipping-button .buttonRow.forward,
#checkoutPayment .checkout-shipping-button .buttonRow.forward,
#checkoutConfirmDefault .checkout-shipping-button .buttonRow.forward,
#checkoutShipAddressDefault .checkout-shipping-button .buttonRow.forward,
#checkoutPayAddressDefault .checkout-shipping-button .buttonRow.forward {
	float: right;
}

#checkoutConfirmDefault .payment-module .important {
	margin-top: 15px;
}

#checkoutConfirmDefault .buttonRow.forward {
	display: inline-block;
}

#checkoutShipping .buttonRow.back,
#checkoutShipAddressDefault .buttonRow.back,
#checkoutPayment .buttonRow.back,
#checkoutPayAddressDefault .buttonRow.back,
#checkoutConfirmDefault .checkout-shipping-button .buttonRow.back {
	float: left;
	line-height: 45px;
	display: inline-block;
}

#checkoutSuccess .content .buttonRow.forward {
	display: inline-block;
	margin: 15px 0 0;
}

/*Account Pages*/
#accountDefault ul {
	padding: 0;
}

/*GV FAQ*/
#discountcouponInfo LABEL.inputLabel {
	width: auto;
	margin-top: 4px;
	margin-right: 10px;
}

#gvFaqDefault #gvFaqDefaultMainContent .gv_faq {
	padding: 0;
	margin: 0;
}

#gvFaqDefault .content .button_redeem,
#gvFaqDefaultContent,
#discountcouponInfo .content .button_send {
	margin: 10px 0 0;
}

#gvFaqDefault .alert h4 {
	margin: 0;
}

/* Account History */
.user-orderhistory {
	border-bottom: 1px solid #E9E9E9;
	display: inline-block;
	line-height: 20px;
	margin: 0 0 30px;
	padding: 0 0 20px;
	width: 100%;
}

.user-orderhistory:last-child {
	border: medium none;
	margin: 0 0 5px;
	padding: 0;
}

.account_history .next-prev {
	margin: 0;
}

.user-orderhistory .change_address {
	margin-top: 10px;
	display: inline-block;
}

#accountHistInfo #orderTotals {
	padding: 10px;
}

#accountHistInfo tr.user-accounthistory {
	border-bottom: 1px solid #E9E9E9;
}

#orderTotals {
	display: inline-block;
	padding-right: 6em !important;
	text-align: right;
	width: 100%;
}

/*Reviews*/
.reviews-list .smallProductImage img {
	display: inline-block;
	height: auto;
	margin-right: 15px;
	width: 100%;
}

.reviews-list blockquote p {
	font-size: 0.8em;
	margin: 15px 0;
}

.reviews-list blockquote .product-review-default footer img,
.reviews-list blockquote .reviews-description img {
	margin-top: 10px;
}

.reviews-list blockquote {
	background: none repeat scroll 0 0 #F9F9F9;
	border-left: 5px solid #E9E9E9;
	padding: 15px 20px;
}

.review-links {
	display: inline-block;
	margin-top: 15px;
}

.review-links>.buttonRow {
	float: left;
	margin: 0 5px 5px 0;
	padding: 2px 7px 5px;
}

blockquote .review-links a,
#reviewsWrite .review-links a {
	color: #FAFAFA !important;
	font-size: 0.8em;
}

#reviewsWrite .ratingRow {
	margin: 1em 0 0;
}

/*Menu*/
.header-container .header a.toggleMenu {
	display: none;
	padding: 10px 15px;
	color: #FAFAFA;
	background-image: url("../images/menuicon.png");
	background-repeat: no-repeat;
	background-position: center right;
	width: 100%;
	font-size: 1.2em;
	text-transform: uppercase;
}

.nav {
	list-style: none;
	*zoom: 1;
}

.nav:before,
.nav:after {
	content: " ";
	display: table;
}

.nav:after {
	clear: both;
}

.nav ul {
	list-style: none;
	width: 13em;
	padding: 0;
}

.go>li>a {
	padding: 10px 13px !important;
}

.nav a {
	padding: 10px 15px;
}

.nav li {
	position: relative;
}

.nav>li {
	float: left;
}

.nav>li>a {
	display: block;
}

.nav li ul {
	position: absolute;
	left: -9999px;
}

.nav>li.hover>ul {
	left: 0;
}

.nav li li.hover>ul {
	left: 100%;
	top: 0;
}

.nav li li a {
	display: block;
	position: relative;
	z-index: 100;
}

.nav li li li a {
	z-index: 200;
}

@media screen and (max-width: 1149px) {
	.active {
		display: block;
	}

	.nav>li {
		float: none;
	}

	.nav>li>.parent {
		background-position: 99% 50%;
	}

	.nav>li>.parent {
		background-image: url("../images/arrows/arrow40.png");
		background-repeat: no-repeat;
		background-position: 99% 50%;
	}

	.nav ul {
		display: block;
		width: 100%;
	}

	.nav>li.hover>ul,
	.nav li li.hover>ul {
		position: static;
	}

}

#centercontent-wrapper .table-responsive {
	border: medium none;
	margin: 0;
}

#myAccountGen li a {
	display: inline-block;
	padding: 5px 0;
}

.columnwith-left {
	left: 25%;
}

#left-column {
	right: 75%;
}

.two-column {
	left: 25%;
}

.three-columns {
	right: 50% !important;
}

#bannerboxall {
	box-shadow: none;
}

#bannerboxall h3 {
	display: none;
}

#bannerboxall #bannerboxallContent>a {
	box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.4);
	padding: 0;
}

#bannerboxall .sideBoxContent {
	padding: 0;
	background: none;
}

#bannerboxall #bannerboxallContent>a:first-child {
	margin-bottom: 30px;
}

button.close {
	min-height: 0;
}

/*Home Page V3*/
.home-top-wrapper,
.footer-v3-wrapper {
	background: url("../images/banners/home_v3.jpg") no-repeat fixed 0 0 #F1F1F1;
}

.home-container,
.footer-v3-container {
	background: none repeat scroll 0 0 #FFFFFF;
}

.homepage_v3 .header-container .header .header-top,
.homepage_v3 .header-container .header .header-content,
.homepage_v3 .header-container .header .nav-maincontainer {
	padding: 0 30px;
}

.homepage_v3 .slideshow-container {
	padding: 30px;
	background: none repeat scroll 0 0 #FFFFFF;
	box-shadow: none;
}

.homepage_v3 .custom-banner-container,
.homepage_v3 .main-top,
.footer-v3-container .custom-banner-container,
.footer-v3-container .custom-content-wrapper,
.footer-v3-container .bottom-banner-wrapper,
.footer-v3-container .brands-wrapper,
.homepage_v3 .main-breadcrumb {
	padding: 30px;
}

.footer-v3-container .footer-wrapper .footer-1,
.footer-v3-container .footer-wrapper #footer-2,
.footer-v3-container .footer-wrapper #footer-3 {
	padding: 30px
}

.footer-v3-container .footer-wrapper .footer-1,
.footer-v3-container .footer-wrapper #footer-2 {
	margin-bottom: 0
}

.footer-v3-container .footer-wrapper #footer-3 {
	padding-bottom: 25px
}

.footer-v3-container .footer-wrapper {
	padding: 0
}

.homepage_v3 .item .product_image .hover_info:hover .zoom-button a,
.homepage_v3 .centerBoxContentsAlsoPurch .product_image .hover_info:hover .zoom-button a,
.homepage_v3 .specialsListBoxContents .product_image .hover_info:hover .zoom-button a {
	left: 28%;
}

.map {
	height: 350px;
	width: 100%;
}

#map1 iframe,
#contactUsDefault .content iframe {
	height: 100%;
	width: 100%;
	border: none;
}

#map-canvas {
	height: 400px;
	margin: 30px 0;
	padding: 0;
}

#map-canvas img {
	max-width: none;
}

/* 
 * 	Core Owl Carousel CSS File
 *	v1.3.2
 */
/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

/* display none until init */
.owl-carousel {
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
	/*overflow: hidden;*/
	position: relative;
	width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
	float: left;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
	cursor: pointer;
}

.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
/*.grabbing { 
    cursor:url(grabbing.png) 8 8, move;
}*/
/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
}

/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.2
*/
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
	filter: Alpha(Opacity=100);
	/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/
.owl-theme .owl-controls .owl-page {
	display: inline-block;
	zoom: 1;
	*display: inline;
	/*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);
	/*IE7 fix*/
	opacity: 0.5;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
	filter: Alpha(Opacity=100);
	/*IE7 fix*/
	opacity: 1;
}

/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers {
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading {
	min-height: 150px;
	background: url(../images/AjaxLoader.gif) no-repeat center center
}

.focusedalert {
	border-color: rgba(82, 168, 236, 0.8);
	box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
	outline: 0 none;

}

.alertmessage {
	margin: 15px 0
}

.s-desc {
	background-color: rgba(14, 11, 95, 0.32);
	border-left: 23px solid #f05e3d;
	bottom: 20px;
	color: #fff;
	display: block;
	font-family: Racing Sans One;
	font-size: 33px;
	line-height: 34px;
	padding: 4px 12px 10px;
	position: absolute;
	transition: all 0.5s ease 0s;
	width: 70%;
}

.s-desc:hover {
	border-left-width: 0;
	border-right: 23px solid #f05e3d;
}

.homecatimg:hover img {
	transform: scale(1.1, 1.1);
}

.homecatimg img {
	height: auto;
	transition: all 0.5s ease 0s;
	width: 100%;
	border: medium none;
}

.camera_bar {
	bottom: -13px;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
}

.box-heading {
	border-bottom: 1px solid #d7d7d7;
	color: #f05e3d;
	font-size: 17px;
	font-weight: bold;
	line-height: 30px;
	margin-bottom: 30px;
	padding-bottom: 9px;
	text-transform: uppercase;
}

.image2 img,
.box .box-content .box-product ul li {
	transition: all 0.5s ease 0s;
}

.box-product ul li .image2 {
	overflow: hidden;
}

.image2 {
	overflow: hidden;
	position: relative;
}

.image2 img,
.image3 img {
	background-color: #f4f4f4;
	vertical-align: top;
}

.box-product .price {
	background-color: #f05e3d;
	bottom: 14px;
	color: #fff;
	line-height: normal;
	padding: 10px 0 10px 10px;
	position: absolute;
	right: 0;
	width: 115px;
}

.price {
	font-family: Racing Sans One;
	font-size: 23px;
}

.price .price-old {
	display: block;
}

.price .price-old {
	font-family: arial;
	font-size: 17px;
	line-height: 20px;
	text-decoration: line-through;
}

.box-product .price {
	color: #fff;
	line-height: normal;
}

.price .price-new {
	line-height: 29px;
}

.image2 img {
	transition: all 0.5s ease 0s;
}

.image2:hover img {
	transform: scale(1.1, 1.1);
}

.cart-button>div {
	float: left;
	text-align: center;
	width: 33.33%;
}

.cart-button a.button {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	color: #7e7e7e;
}

.cart-button a>span {
	display: none !important;
	padding: 0 !important;
	visibility: hidden !important;
}

.cart-button a {
	border-left: 1px solid #d7d7d7;
	display: block;
	float: left;
	padding: 8px 0;
	position: relative;
	text-align: center;
	width: 100%;
}

.tooltip-1 {
	white-space: nowrap;
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	color: #7e7e7e;
}

.cart-button {

	max-width: 170px;
	text-align: left;
}

.cart-button .cart a {
	border: 1px solid #d7d7d7;
}

.cart-button a {
	border-left: 1px solid #d7d7d7;
	display: block;
	float: left;
	padding: 8px 0;
	position: relative;
	text-align: center;
	width: 100%;
}

.cart i.fa {
	margin-right: 0 !important;
}

.price.price-min {
	display: none;
}

.price .price-new {
	line-height: 29px;
}

.price .price-old {
	font-family: arial;
	font-size: 17px;
	line-height: 20px;
	text-decoration: line-through;
}

.price {
	font-family: Racing Sans One;
	font-size: 23px;
}

.last_line {
	padding-left: 3px !important;
	padding-right: 3px !important;
	height: 420px;
	margin-bottom: 15px;
	overflow: hidden;

}

.name {
	margin: 14px 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

#loginregist {
	z-index: 99999999;
}

.greencolor {
	color: #00CD00;
}

#alertmode {
	z-index: 99999999;
}

.panel-login {
	border-color: #fff;
/* 	-webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);*/
	box-shadow: none;
}

.panel-login>.panel-heading {
	color: #00415d;
	background-color: #fff;
	border-color: #fff;
	text-align: center;
}

.panel-login>.panel-heading a {
	text-decoration: none;
	color: #666;
	font-weight: bold;
	font-size: 15px;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	transition: all 0.1s linear;
}

.panel-login>.panel-heading a.active {
	color: #029f5b;
	font-size: 18px;
}

.panel-login>.panel-heading hr {
	margin-top: 10px;
	margin-bottom: 0px;
	clear: both;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}

.panel-login input[type="text"],
.panel-login input[type="email"],
.panel-login input[type="password"] {
	width: 75%;
	height: 45px;
	border: 1px solid #ddd;
	font-size: 16px;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	transition: all 0.1s linear;
}

.panel-login input:hover,
.panel-login input:focus {
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-color: #ccc;
}

.btn-login {
	background-color: #59B2E0;
	outline: none;
	color: #fff;
	font-size: 14px;
	height: auto;
	font-weight: normal;
	padding: 14px 0;
	text-transform: uppercase;
	border-color: #59B2E6;
}

.btn-login:hover,
.btn-login:focus {
	color: #fff;
	background-color: #53A3CD;
	border-color: #53A3CD;
}

.forgot-password {
	text-decoration: underline;
	color: #888;
}

.forgot-password:hover,
.forgot-password:focus {
	text-decoration: underline;
	color: #666;
}

.btn-register {
	background-color: #1CB94E;
	outline: none;
	color: #fff;
	font-size: 14px;
	height: auto;
	font-weight: normal;
	padding: 14px 0;
	text-transform: uppercase;
	border-color: #1CB94A;
}

.btn-register:hover,
.btn-register:focus {
	color: #fff;
	background-color: #1CA347;
	border-color: #1CA347;
}

/*************************************************************************************/
@media (max-width: 767px) {
	.box-product .price {
		display: none;
	}

	.box-content .box-product li {
		border-bottom: 1px dotted #D7D7D7;
		margin-bottom: 15px !important;
		padding-bottom: 15px;
	}

	.cart-button>div.wishlist {
		display: none;
	}

	.cart-button .compare {
		display: none;
	}

	.cart-button .cart a.addToCart:hover {
		background: #323232 none repeat scroll 0 0 !important;
	}

	.cart-button .cart a {
		background-color: #f05e3d;
		color: #ffffff;
		display: inline-block;
		font-family: Arial, sans-serif;
		font-size: 13px;
		font-weight: normal;
		line-height: 23px;
		padding: 5px 10px;
		width: auto;
	}

	.cart-button .cart a i {
		display: none;
		font-size: 15px;
		vertical-align: middle;
		width: 1.25em;
	}

	.cart-button a span {
		display: inline-block !important;
		visibility: visible !important;
	}

	#content .box-product .inner {
		float: left;
		margin-left: 30px;

	}

	.price.price-min {
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
		color: #f05e3d;
		display: block;
		font-family: Arial;
		font-size: 15px;
		font-weight: bold;
		margin-bottom: 4px;
		padding: 0;
		position: static;
	}

	.price-old {
		display: inline-block;
		font-size: 15px;

	}

	.price-new {
		display: inline-block;


	}

	.price-old {
		color: #7e7e7e;
		font-weight: normal;
	}

	.price .price-old {
		color: #7e7e7e;
		font-weight: normal;
	}

	.last_line {
		height: 375px !important;
	}

	.needhide {
		display: block !important;

	}



}

/*************************************************************************************/

.protitlespecial {
	height: 37px;
	overflow: hidden;
	text-overflow: clip;
}

.thumbnails li>.fff .caption {
	background: #fff !important;
	padding: 10px
}

/* Page Header */
.page-header {
	background: #f9f9f9;
	margin: -30px -40px 40px;
	padding: 20px 40px;
	border-top: 4px solid #ccc;
	color: #999;
	text-transform: uppercase;
}

.page-header h3 {
	line-height: 0.88rem;
	color: #000;
}

ul.thumbnails {
	margin-bottom: 0px;
}



/* Thumbnail Box */
.caption h4 {
	color: #444;
}

.caption p {
	color: #999;
}



/* Carousel Control */
.control-box {
	text-align: right;
	width: 100%;
}

.carousel-controlsi {
	background: #666;
	border: 0px;
	border-radius: 0px;
	display: inline-block;
	font-size: 34px;
	font-weight: 200;
	line-height: 18px;
	opacity: 0.5;
	padding: 4px 10px 0px;
	position: static;
	height: 30px;
	width: 15px;
}



/* Mobile Only */
@media (max-width: 767px) {

	.page-header,
	.control-box {
		text-align: center;
	}
}

@media (max-width: 479px) {
	.caption {
		word-break: break-all;
	}
}


li {
	list-style-type: none;
}

.carousel-control.mkright {

	left: auto;
	right: -30px;
}

.custom-banner-second {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;

}

.custom-banner-container {
	margin-top: 20px;
	display: inline-block;
	/* height: 200px; */
}

#whatsNew,
#featuredProducts {
	margin-top: 20px;
}

#specialsDefault {
	margin-top: 10px;
}

.thi {
	height: 400px;
}

.sl {
	font-size: 11px;
}

.sa {
	font-size: 21px;
}

.attalert {
	border: 1px solid red !important;
}

.needhide {
	display: none;
	border: medium none !important;

}

.shipinfo-delivery-address {
	margin-top: 20px;
}

.proicon {
	margin-top: 5px;
}

.centerBoxWrapper header>h3 {
	border: 3px solid #ff7260;
	color: #ff7260;
}

.centerBoxWrapper header>h3 {
	background: #fff none repeat scroll 0 0;
	display: inline-block;
	font-size: 1.4em;
	line-height: 22px;
	margin: 0;
	padding: 10px;
	position: relative;
	text-transform: uppercase;
}

#centercontent-wrapper header>h3,
.checkout-steps {
	background: #fff none repeat scroll 0 0;
	display: inline-block;
	font-size: 1.4em;
	line-height: 22px;
	margin: 0;
	padding: 10px;
	position: relative;
	text-transform: uppercase;
}

#captcha-img,
.captcha-input {
	vertical-align: middle;
}

.clearBoth {
	clear: both;
}

.captcha-input {
	display: inline !important;
	width: 150px !important;
}

#capchimg {
	margin-left: 5px;
}

#attrib-9 {
	margin-left: 10px;
}

#loginregistdiv {
	margin: 0 auto;
}

.nevfl {
	margin: 0 auto;
	width: 50%;
}

/*==========Validform===========*/
.Validform_checktip {
	margin-left: 8px;
	line-height: 20px;
	height: 20px;
	overflow: hidden;
	color: #999;
	font-size: 12px;
}

.Validform_right {
	color: #71b83d;
	padding-left: 20px;
	background: url(../images/validform/right.png) no-repeat left center;
}

.Validform_wrong {
	color: red;
	padding-left: 20px;
	white-space: nowrap;
	background: url(../images/validform/error.png) no-repeat left center;
}

.Validform_loading {
	padding-left: 20px;
	background: url(../images/validform/onLoad.gif) no-repeat left center;
}

.Validform_error {
	background-color: #ffe7e7;
}

#Validform_msg {
	color: #7d8289;
	font: 12px/1.5 tahoma, arial, \5b8b\4f53, sans-serif;
	width: 280px;
	-webkit-box-shadow: 2px 2px 3px #aaa;
	-moz-box-shadow: 2px 2px 3px #aaa;
	background: #fff;
	position: absolute;
	top: 0px;
	right: 50px;
	z-index: 99999;
	display: none;
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=135, Color='#999999');
}

#Validform_msg .iframe {
	position: absolute;
	left: 0px;
	top: -1px;
	z-index: -1;
}

#Validform_msg .Validform_title {
	line-height: 25px;
	height: 25px;
	text-align: left;
	font-weight: bold;
	padding: 0 8px;
	color: #fff;
	position: relative;
	background-color: #000;
}

#Validform_msg a.Validform_close:link,
#Validform_msg a.Validform_close:visited {
	line-height: 22px;
	position: absolute;
	right: 8px;
	top: 0px;
	color: #fff;
	text-decoration: none;
}

#Validform_msg a.Validform_close:hover {
	color: #cc0;
}

#Validform_msg .Validform_info {
	padding: 8px;
	border: 1px solid #000;
	border-top: none;
	text-align: left;
}

.npi {
	padding: 0 !important;
}

.thi div a>img {
	height: 250px !important;
}

.image2 img {
	height: 270px !important;
}

#rlerrmsg,
#errmsgri {
	display: none;
	color: #F00
}

.fr {
	float: right;
}

.tc {
	text-align: center;
}

.qcl {
	color: #AAAAAA;
}

.mt20 {
	margin-top: 20px;
}

.ml10 {

	margin-left: 10px;

}
/* 添加 */
.carousel-control{
	width: 5%!important;
}
.input-label{
	font-size: 14px!important;
	line-height: 40px!important;
	text-align: right!important;
}